import { toast } from "react-toastify";
import { ajaxGateway } from "./http";

interface ApiResponse {
  state: {
    code: number;
    msg: string;
  };
  data?: any;
}

type Callback = () => void;

let API: { [key: string]: any } = {};

API = new Proxy(API, {
  get(target, group: string) {
    if (group === "r") {
      // process err
      return function r(res: ApiResponse, cb: Callback) {
        if (res.state.code) {
          return toast.error(res.state.msg);
        }
        cb();
      };
    }
    return new Proxy(
      {},
      {
        get(target, methodName: string) {
          return function (params?: object) {
            params = params || {};
            return ajaxGateway({
              mt: group + "." + methodName,
              data: params,
            });
          };
        },
      },
    );
  },
});
const _window = window as any;
_window.API = API;
export default API;
