import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

const SolPriceContext = createContext(0);

export const useSolPrice = () => useContext(SolPriceContext);

interface ProviderProps {
    children: ReactNode;
}

export const SolPriceProvider: React.FC<ProviderProps> = ({ children }) => {
  let [solPrice, setSolPrice] = useState(0);

  useEffect(() => {

    async function getSolPrice() {
      let price = 0

      try {
        const res = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd")
        .then((response) => {
          return response.json()
        })
        console.log(res);
        
        if (res.solana.usd) {
          price = res.solana.usd
        }
      } catch (error) {
        console.log("error: ", error);
      }

      return price
    }

    const fetchSolPrice = async () => {

      const price = await getSolPrice()
      if (price) {
        setSolPrice(price);
      }
      
      // price update
      const updatePrice = setInterval(async () => {
        const price = await getSolPrice()
        if (price) {
          setSolPrice(price);
        }
      }, 60000);

      // Clean up interval on component unmount
      return () => clearInterval(updatePrice);
    };
    fetchSolPrice();
  }, []);

  return (
    <SolPriceContext.Provider value={solPrice}>
      {children}
    </SolPriceContext.Provider>
  );
};
