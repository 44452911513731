import { useParams, useNavigate, Link } from "react-router-dom";
import API from "../API";
import { useEffect, useRef, useState } from "react";
import { useMint } from "../hooks/useMint";

import { CONGRESS_CANDY_MACHINE_ID_STR } from "../anchor/setup";
import { Metaplex } from "@metaplex-foundation/js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";

export default function RareNft() {
  const [mint, loadingMap] = useMint();

  const wallet = useWallet();

  const { connection } = useConnection();
  const metaplex = new Metaplex(connection);
  const candyMachineId = new PublicKey(CONGRESS_CANDY_MACHINE_ID_STR);

  async function getMintedCount() {
    const candyMachine = await metaplex.candyMachines().findByAddress({
      address: candyMachineId,
    });

    // 获取已经mint的数量
    const mintedCount = candyMachine.itemsMinted.toNumber();
    console.log("Minted count:", mintedCount);
    setMinted(mintedCount);
  }

  const maxMintCount = 5;
  const [mintAmountA, setMintAmountA] = useState(1);
  const [minted, setMinted] = useState(0);

  useEffect(() => {
    getMintedCount();
  }, []);

  return (
    <>
      <div className="mx-auto grid max-w-[1600px] grid-cols-[460px_1fr] gap-16">
        <div className="ct-cover-stage-bg">
          <div className="ct-cover-bg mx-auto w-[364px] p-[12px]">
            <img
              className="w-full object-cover"
              src="/rareNft/cover.png"
              alt=""
            />
          </div>
        </div>

        <div>
          <div className="mb-14">
            <h3 className="ct-font-silk ct-text-gt1 mb-3 text-[2.5rem]">
              CALL OF MEME:congress member
            </h3>
            <p className="leading-8 text-[#C1C1C1]">
              The CALL Genesis Diamond (CGD) NFT serves as a Congress Member
              credential on the CALL.MEME platform, symbolizing the holder's
              significant contributions during the platform's early stages. CGD
              holders enjoy exclusive benefits, including platform airdrops,
              enhanced interaction rewards, ongoing profit-sharing, membership
              in the platform council, and opportunities to actively participate
              in platform development.
            </p>
            <p className="leading-8 text-[#C1C1C1]">
              Airdrop allocation: 240,000,000 $CALL (released in 3 months)
            </p>
            <p className="leading-8 text-[#C1C1C1]">Trading buff: 100%</p>
            <p className="leading-8 text-[#C1C1C1]">
              Profit distribution: 0.8%
            </p>
          </div>

          <div className="mb-14 flex gap-6">
            <div className="rounded-3xl bg-[#222] py-6 pl-9 pr-14">
              <img src="/rareNft/i_user.svg" className="mb-3" alt="" />
              <p className="flex items-center text-white">
                {/* TOOD: GET Total Minted */}
                <span className="text-3xl font-bold">{minted}</span>
                <span className="mx-2">/</span>
                12
              </p>
              <p className="text-sm">Total Minted</p>
            </div>
            <div className="w-[30rem] rounded-3xl bg-[#222] px-10 pt-6">
              <div className="mb-6 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span className="text-sm">Sale Price:</span>
                  <span className="text-xl text-white">40 SOL</span>
                </div>
                {/* <div className="flex items-center justify-between">
                  <div className="flex gap-1">
                    <button
                      className="btn h-10 min-h-min bg-[#121314] text-3xl text-[#666]"
                      onClick={() => {
                        if (mintAmountA - 1 >= 0) {
                          setMintAmountA(mintAmountA - 1);
                        }
                      }}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      pattern="\d*"
                      className="ct-num-input input h-10 w-16 bg-[#121314] pl-0 text-center text-3xl text-white focus:outline-none"
                      value={mintAmountA}
                      onChange={(e) => {
                        const amount = parseInt(e.target.value);
                        setMintAmountA(amount);
                      }}
                    />
                    <button
                      className="btn h-10 min-h-min bg-[#121314] text-3xl text-white"
                      onClick={() => {
                        setMintAmountA(mintAmountA + 1);
                        if (mintAmountA >= maxMintCount) {
                          setMintAmountA(maxMintCount);
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                </div> */}
              </div>
              <button
                onClick={() => {
                  mint({
                    amount: mintAmountA,
                    mintType: "congressMember",
                  });
                }}
                className="btn h-10 min-h-min w-full bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
              >
                {loadingMap["congressMember"] ? (
                  <span className="loading loading-infinity loading-lg text-[#4c4c4c]"></span>
                ) : (
                  "Mint"
                )}
              </button>
            </div>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <img
              src="/rareNft/Apollo.png"
              alt="Apollo"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Ceres.png"
              alt="Ceres"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Diana.png"
              alt="Diana"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Juno.png"
              alt="Juno"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Jupiter.png"
              alt="Jupiter"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Mars.png"
              alt="Mars"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Mercury.png"
              alt="Mercury"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Minerva.png"
              alt="Minerva"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Neptune.png"
              alt="Neptune"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Venus.png"
              alt="Venus"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Vesta.png"
              alt="Vesta"
              className="w-full object-cover"
            />
            <img
              src="/rareNft/Vulcan.png"
              alt="Vulcan"
              className="w-full object-cover"
            />
          </div>
        </div>
      </div>
    </>
  );
}
