import { ChainConfig } from "./setup.common";

export const prodChainConfig: ChainConfig = {
  programId: "ERtymHEiAuD5tHzrAvaYriWHYfTu2hkyN9fR31X29PUM",
  voteProgramId: "563jmVtJoKiM8ceZJ9a3J54azUjje5BY4byFX5jszPhC",
  tokenIssuer: "hdJNuxkEv8Lns2Sz5ZqmkCU1pwYrb5LZjPL2AHDCcys",
  voteAdminAddress: "hdJNuxkEv8Lns2Sz5ZqmkCU1pwYrb5LZjPL2AHDCcys",
  callTokenAddress: "BnECco6cFn9czAt6zrc4DtEGuTP81TUEfJc28FTo5yGM",
  voteTokenAddress: "8nUuDKPzEuYrWNdfF6bnGxdMVu6itFfBAxiZsQBT2S6A",
  merkleDistributorProgramId: "HVdsBzUkcdBjkT5KLEekPZxdYmJ6goiMc2TYXWk8cubK",
  heliusRPCEndpoint: "https://carrie-az7pzj-fast-mainnet.helius-rpc.com",
  congressCandyMachineId: "CeMcdstQbzi9w7wrfj9LA18nb4kHuXZPwucAdfMBNP37",
  congressCollectionAddress: "2umqz5SFwMA7D8pPnYEVvLwbGVJT8caE63sHU9ELjcm2",
  goldCandyMachineId: "8Yk2fdg26gkpoeDzbq5VvVwbu9KVDmyijXaCDUuaHDLS",
  goldCollectionAddress: "AwD5uPvfoXW48K8XJoaRELvFzfAyByPa8xvjSGbKrRDY",
  silverCandyMachineId: "7wUDXvVcJT44RmbNY6K1ybJ79gDaFMijPXAE5hqsE1ut",
  silverCollectionAddress: "9Zit9ooeZhavFf1kgzFaZ6xpZ1bTZvbw7qMcpPBg9f3m",
  treasuryAddress: "GTneE6G6kguaM81zYRok9P8v7pDxDNn7CNpPNy3YeFXE",
};
