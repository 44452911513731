import { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import API from "../API";
import { getUserInfo } from "../stores/userStore";
import Countdown from "../components/Countdown";
import { formatDate, num2str } from "../lib/utils";

export default function Hours48rush() {
  /*
    rushId	long	 Id 1
    startTme	date	  POSIX time 2024-09/08 18:02:26
    endTime	date	  POSIX time 2024-09/08 18:02:26
    pricePool	string	  string1
    rushTraderInfoRespList	[Api_MEME_RushTraderInfoResp]
  */
  let [rushTrader, setRushTrader] = useState<any>({
    rushTraderInfoRespList: [],
  });

  /*
      address
      nickName
      avatar
      score
      expectAward
      rank
  */
  let [user48HoursRushInfo, setUser48HoursRushInfo] = useState({
    address: "",
    nickName: "",
    avatar: "",
    score: 0,
    expectAward: "",
    rank: 0,
  });

  let userInfo = getUserInfo();
  let isLogin = userInfo.userToken;

  //meme.get48HoursRushTraderList  48HoursRush
  const get48HoursRushTraderList = async function () {
    let res = await API.meme.get48HoursRushTraderList();
    console.log("get48HoursRushTraderList", res);
    setRushTrader(res.data || {});

    //48HoursRush
    if (isLogin && res.data.rushId) {
      let res1 = await API.meme.getUser48HoursRushInfo({
        rushId: res.data.rushId,
      });
      if (res1.data) {
        setUser48HoursRushInfo(res1.data);
      }
    }
  };

  useEffect(() => {
    get48HoursRushTraderList();
  }, []);

  return (
    <>
      {/* 48 Hours rush -Section */}
      <div className="mx-auto mb-20 flex max-w-[1400px] flex-col items-center gap-4">
        <h1 className="ct-font-silk ct-text-gt3 text-[2.5rem]">
          48 Hours rush
        </h1>
        <p className="text-center text-2xl text-[#C1C1C1]">
          After a token is launched to Ray dium for 48 hours, it will be scored
          according to its max Market Cap, current Market Cap, the number of
          Holders, etc.Top holders will eam Rush Points, based on Tokens' score
          at that time.We will have Leaderboard for evert 48 hours. And then the
          prize will be given to top 4 players in this Leaderboard.
        </p>
      </div>

      {/* Total Prize -Section */}
      <div className="mx-auto mb-16 grid max-w-[1200px] grid-cols-2 text-center">
        <div>
          <div className="mb-2 text-3xl font-bold tracking-wider text-[#FFFFFF]">
            {num2str(Number(rushTrader.pricePool))} SOL
          </div>
          <div className="text-lg">Total Prize</div>
        </div>
        <div>
          <div className="mb-2 text-3xl font-bold tracking-widest text-[#FFFFFF]">
            <Countdown endTime={rushTrader.endTime}>
              {({ expired, formatTime }) => (
                <>
                  <span>{formatTime.hours}</span>H
                  <span className="mx-5">:</span>
                  <span>{formatTime.minutes}</span>M
                  <span className="mx-5">:</span>
                  <span>{formatTime.seconds}</span>S
                </>
              )}
            </Countdown>
          </div>

          <div className="text-lg">
            From {formatDate(rushTrader.startTime, "MM/dd/yyyy hh:mm:ss")}-{" "}
            {formatDate(rushTrader.endTime, "MM/dd/yyyy hh:mm:ss")}
          </div>
        </div>
      </div>

      {/* Score List -Section */}
      <div className="mx-auto max-w-[1200px] border border-white bg-[#111111]">
        <div className="grid h-[75px] grid-flow-col items-center justify-between bg-[rgb(0_240_255)] bg-opacity-20 px-6 text-center text-[#fff]">
          <div className="mr-56 flex items-center gap-2">
            <img
              src={user48HoursRushInfo.avatar || "/logo.png"}
              className="h-10 w-10 rounded-full object-cover"
              alt=""
            />
            <Link to={""} className="text-[#BFDBFE]">
              {user48HoursRushInfo.address.slice(0, 6)}
            </Link>
          </div>
          <div>
            <div className="text-xl">
              #
              {user48HoursRushInfo.rank > 999
                ? "999+"
                : user48HoursRushInfo.rank}
            </div>
            <div className="text-sm text-[#CACACA]">Your Rank</div>
          </div>
          <div>
            <div className="text-xl">{user48HoursRushInfo.score || 0}</div>
            <div className="text-sm text-[#CACACA]">Score</div>
          </div>
          <div>
            <div className="text-xl">{user48HoursRushInfo.expectAward}</div>
            <div className="text-sm text-[#CACACA]">exp.Prize</div>
          </div>
          <Link
            to={`/h48ra/${rushTrader.rushId}`}
            className="btn ml-56 h-10 min-h-10 rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-black"
          >
            Get higher score
          </Link>
        </div>
        <div className="px-10 pb-7">
          <table className="w-full border-separate border-spacing-y-1.5 text-center text-sm">
            <thead>
              <tr className="h-11 text-[#8E8E92]">
                <th className="font-normal"># Rank</th>
                <th className="font-normal">Name</th>
                <th className="font-normal">Score</th>
                <th className="font-normal">
                  TX Pts
                  {/* <span
                    className="tooltip tooltip-bottom cursor-pointer"
                    data-tip="transaction points"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M8.75 0.25C3.92188 0.25 0 4.17188 0 9C0 13.8281 3.92188 17.75 8.75 17.75C13.5781 17.75 17.5 13.8281 17.5 9C17.5 4.17188 13.5781 0.25 8.75 0.25ZM9.375 14H8.125V12.75H9.375V14ZM9.59375 10.0312L9.48438 10.125C9.42187 10.1719 9.375 10.2812 9.375 10.375V11.5156H8.125V10.375C8.125 9.89063 8.34375 9.4375 8.70312 9.14062L8.8125 9.04688C9.90625 8.17188 10.4375 7.71875 10.4375 6.9375C10.4375 6 9.6875 5.25 8.75 5.25C7.78125 5.25 7.0625 5.96875 7.0625 6.9375H5.8125C5.8125 5.29688 7.10938 4 8.75 4C10.375 4 11.6875 5.3125 11.6875 6.9375C11.6875 8.35938 10.7188 9.14062 9.59375 10.0312Z"
                        fill="#B8B8B8"
                      />
                    </svg>
                  </span> */}
                </th>
                <th className="font-normal">exp. Prize</th>
              </tr>
            </thead>
            <tbody className="text-[#fff]">
              {rushTrader.rushTraderInfoRespList.map((_: any, item: any) => (
                <tr key={item} className="h-11 bg-[#151717]">
                  <td>
                    <div className="flex justify-center">
                      {item === 0 && (
                        <img src={`./top${item + 1}.svg`} alt="" />
                      )}
                      {item === 1 && (
                        <img src={`./top${item + 1}.svg`} alt="" />
                      )}
                      {item === 2 && (
                        <img src={`./top${item + 1}.svg`} alt="" />
                      )}
                      {item > 2 && item + 1}
                    </div>
                  </td>
                  <td className="">
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src={_.avatar || "/logo.png"}
                        className="h-5 w-5 rounded-3xl border border-[#303030]"
                        alt=""
                      />
                      <Link to={""} className="text-[#BFDBFE]">
                        {_.address.slice(0, 6)}
                      </Link>
                    </div>
                  </td>
                  <td>{num2str(_.score)}</td>
                  <td>{num2str(_.integral)}</td>
                  <td>{_.expectAward}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
