import { useState } from "react";
import {
  useConnection,
  useWallet,
  useAnchorWallet,
} from "@solana/wallet-adapter-react";
import { BN } from "bn.js";

import { programId, tokenIssuer, RPC, dev, vault } from "../anchor/setup";
import {
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import {
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import { AnchorProvider, Wallet, Idl, Program } from "@coral-xyz/anchor";
import IDL from "../anchor/pumpfun.json";

import { toast } from "react-toastify";

global.Buffer = require("buffer").Buffer;

const SOL: PublicKey = new PublicKey(
  "So11111111111111111111111111111111111111112",
);

export const useBuy = () => {
  const { publicKey, sendTransaction } = useWallet();
  const wallet = useAnchorWallet();
  const { connection } = useConnection();
  const [isLoading, setIsLoading] = useState(false);

  async function getProvider(rpc: string, wallet: any) {
    const provider = new AnchorProvider(connection, wallet, {
      skipPreflight: true,
    });
    return provider;
  }

  const buy = async ({
    amount,
    splToken,
    poolAdminPubKey,
  }: {
    amount: string;
    splToken: PublicKey;
    poolAdminPubKey: PublicKey;
  }) => {
    if (!publicKey || !wallet) return;
    setIsLoading(true);

    try {
      console.log("buy address", splToken.toBase58());
      const splTokenMint = new PublicKey(splToken);

      const vaultFeeInfo = PublicKey.findProgramAddressSync(
        [Buffer.from("initialize")],
        programId,
      )[0];
      console.log("vaultFeeInfo", vaultFeeInfo.toBase58());

      const pool = PublicKey.findProgramAddressSync(
        [
          poolAdminPubKey.toBuffer(),
          splTokenMint.toBuffer(),
          Buffer.from("pool"),
        ],
        programId,
      )[0];
      console.log("pool", pool.toBase58());

      const input = new BN(Number(amount) * LAMPORTS_PER_SOL);
      const poolNativeAccount = PublicKey.findProgramAddressSync(
        [
          splTokenMint.toBuffer(),
          tokenIssuer.toBuffer(),
          Buffer.from("pool_native_account_seed"),
        ],
        programId,
      )[0];
      console.log("poolNativeAccount", poolNativeAccount.toBase58());

      const traderTokenAccount = getAssociatedTokenAddressSync(
        splTokenMint,
        publicKey,
        true,
      );
      console.log("traderTokenAccount", traderTokenAccount.toBase58());
      const poolTokenAccount = getAssociatedTokenAddressSync(
        splTokenMint,
        poolNativeAccount,
        true,
      );
      console.log("poolTokenAccount", poolTokenAccount.toBase58());

      const provider = await getProvider(RPC, wallet);
      const program = new Program(IDL as Idl, programId, provider);

      let account_list = {
        pool: pool,
        trader: publicKey,
        splTokenMint: splTokenMint,
        vaultFeeInfo: vaultFeeInfo,
        vault: vault,
        dev: dev,
        poolTokenAccount: poolTokenAccount,
        poolNativeAccount: poolNativeAccount,
        traderTokenAccount: traderTokenAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        rent: SYSVAR_RENT_PUBKEY,
      };

      let transaction = await program.methods
        .swapExactSolsForTokens(input, new BN(100))
        .accounts(account_list)
        .transaction();

      try {
        let tx = await provider.sendAndConfirm(transaction);
        console.log(`https://solscan.io/tx/${tx}?cluster=devnet`);
        return tx;
      } catch (error) {
        toast.error("transaction fail!");
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return [buy, isLoading] as const;
};
