import BN from "bn.js";
import {
  Connection,
  PublicKey,
  SystemProgram,
  LAMPORTS_PER_SOL,
  AccountMeta,
  Transaction,
  TransactionInstruction,
  ComputeBudgetProgram,
} from "@solana/web3.js";
import {
  createCloseAccountInstruction,
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  NATIVE_MINT,
  createAssociatedTokenAccountInstruction,
  getAssociatedTokenAddress,
  createSyncNativeInstruction,
} from "@solana/spl-token";

import {
  getAuthAddress,
  getPoolVaultAddress,
  getPoolLpMintAddress,
  getOrcleAccountAddress,
} from "../anchor/pda";
import { DEVNET_PROGRAM_ID, tokenIssuer } from "../anchor/setup";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { u64, struct } from "../anchor/marshmallow";
import { useState } from "react";
import { createOrGetTokenAccount } from "../anchor/createOrGetTokenAccount";
import { convertToLamports } from "../lib/utils";

global.Buffer = require("buffer").Buffer;
// const DEVNET_RPC =
// "https://devnet.helius-rpc.com/?api-key=d4b066f7-15d5-45ad-ac46-cde3eb26028a";
const DEVNET = true;
//  const connection = new Connection(DEVNET_RPC, "confirmed");
const admin = tokenIssuer;
// const buyer: PublicKey = new PublicKey("11111111111111111111111111111111");

interface ProgramAddress {
  publicKey: PublicKey;
  nonce: number;
}
function findProgramAddress(
  seeds: Array<Buffer | Uint8Array>,
  programId: PublicKey,
): {
  publicKey: PublicKey;
  nonce: number;
} {
  const [publicKey, nonce] = PublicKey.findProgramAddressSync(seeds, programId);
  return { publicKey, nonce };
}

function u16ToBytes(num: number): Uint8Array {
  const arr = new ArrayBuffer(2);
  const view = new DataView(arr);
  view.setUint16(0, num, false);
  return new Uint8Array(arr);
}

const AMM_CONFIG_SEED = Buffer.from("amm_config", "utf8");

const POOL_SEED = Buffer.from("pool", "utf8");
function getCpmmPdaAmmConfigId(
  programId: PublicKey,
  index: number,
): {
  publicKey: PublicKey;
  nonce: number;
} {
  return findProgramAddress([AMM_CONFIG_SEED, u16ToBytes(index)], programId);
}
function orderMints(
  mintX: PublicKey,
  amountX: BN,
  mintY: PublicKey,
  amountY: BN,
): [PublicKey, BN, PublicKey, BN] {
  let mintA, mintB;
  let amountA, amountB;
  if (Buffer.compare(mintX.toBuffer(), mintY.toBuffer()) < 0) {
    mintA = mintX;
    amountA = amountX;
    mintB = mintY;
    amountB = amountY;
  } else {
    mintA = mintY;
    amountA = amountY;
    mintB = mintX;
    amountB = amountX;
  }

  return [mintA, amountA, mintB, amountB];
}

function getCpmmPdaPoolId(
  programId: PublicKey,
  ammConfigId: PublicKey,
  mintA: PublicKey,
  mintB: PublicKey,
): {
  publicKey: PublicKey;
  nonce: number;
} {
  return findProgramAddress(
    [POOL_SEED, ammConfigId.toBuffer(), mintA.toBuffer(), mintB.toBuffer()],
    programId,
  );
}

const RENT_PROGRAM_ID = new PublicKey(
  "SysvarRent111111111111111111111111111111111",
);
const SYSTEM_PROGRAM_ID = SystemProgram.programId;

const anchorDataBuf = {
  initialize: [175, 175, 109, 31, 13, 152, 155, 237],
  deposit: [242, 35, 198, 137, 82, 225, 242, 182],
  withdraw: [183, 18, 70, 156, 148, 109, 161, 34],
  swapBaseInput: [143, 190, 90, 218, 196, 30, 51, 222],
  swapBaseOutput: [55, 217, 98, 86, 163, 74, 180, 173],
};

interface RaydiumCpSwapAccounts {
  programId: PublicKey;
  creator: PublicKey;
  configId: PublicKey;
  authority: PublicKey;
  poolId: PublicKey;
  poolStateAddress: PublicKey;
  mintA: PublicKey;
  mintB: PublicKey;
  lpMint: PublicKey;
  userVaultA: PublicKey;
  userVaultB: PublicKey;
  userLpAccount: PublicKey;
  vaultA: PublicKey;
  vaultB: PublicKey;
  createPoolFeeAccount: PublicKey;
  mintProgramA: PublicKey;
  mintProgramB: PublicKey;
  observationId: PublicKey;
  amountA: BN;
  amountB: BN;
}

const wSOL = new PublicKey("So11111111111111111111111111111111111111112");

async function getCpSwapAccounts(
  creator: PublicKey,
  token: PublicKey,
  solAmount: BN,
  tokenAmount: BN,
): Promise<RaydiumCpSwapAccounts> {
  const programId = DEVNET_PROGRAM_ID.CREATE_CPMM_POOL_PROGRAM;
  const createPoolFee = DEVNET_PROGRAM_ID.CREATE_CPMM_POOL_FEE_ACC;

  const [token0, amount0, token1, amount1] = orderMints(
    wSOL,
    solAmount,
    token,
    tokenAmount,
  );
  const feeConfigs = [
    {
      id: "D4FPEruKEHrG5TenZ2mpDGEfu1iUvTiqBxvpU8HLBvC2",
      index: 0,
      protocolFeeRate: 120000,
      tradeFeeRate: 2500,
      fundFeeRate: 40000,
      createPoolFee: "150000000",
    },
    {
      id: "G95xxie3XbkCqtE39GgQ9Ggc7xBC8Uceve7HFDEFApkc",
      index: 1,
      protocolFeeRate: 120000,
      tradeFeeRate: 10000,
      fundFeeRate: 40000,
      createPoolFee: "150000000",
    },
    {
      id: "2fGXL8uhqxJ4tpgtosHZXT4zcQap6j62z3bMDxdkMvy5",
      index: 2,
      protocolFeeRate: 120000,
      tradeFeeRate: 20000,
      fundFeeRate: 40000,
      createPoolFee: "150000000",
    },
    {
      id: "C7Cx2pMLtjybS3mDKSfsBj4zQ3PRZGkKt7RCYTTbCSx2",
      index: 3,
      protocolFeeRate: 120000,
      tradeFeeRate: 40000,
      fundFeeRate: 40000,
      createPoolFee: "150000000",
    },
  ];

  if (DEVNET) {
    feeConfigs.forEach((config) => {
      config.id = getCpmmPdaAmmConfigId(
        DEVNET_PROGRAM_ID.CREATE_CPMM_POOL_PROGRAM,
        config.index,
      ).publicKey.toBase58();
    });
  }

  const creatorToken0 = getAssociatedTokenAddressSync(
    token0,
    admin,
    true,
    TOKEN_PROGRAM_ID,
  );
  const creatorToken1 = getAssociatedTokenAddressSync(
    token1,
    admin,
    true,
    TOKEN_PROGRAM_ID,
  );

  const [authority] = await getAuthAddress(programId);

  const configAddress = new PublicKey(feeConfigs[0].id);
  const poolStateAddress = getCpmmPdaPoolId(
    programId,
    configAddress,
    token0,
    token1,
  ).publicKey;

  const [lpMintAddress] = await getPoolLpMintAddress(
    poolStateAddress,
    programId,
  );
  const [vault0] = await getPoolVaultAddress(
    poolStateAddress,
    token0,
    programId,
  );
  const [vault1] = await getPoolVaultAddress(
    poolStateAddress,
    token1,
    programId,
  );
  const [creatorLpTokenAddress] = await PublicKey.findProgramAddress(
    [creator.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), lpMintAddress.toBuffer()],
    ASSOCIATED_TOKEN_PROGRAM_ID,
  );

  const [observationAddress] = await getOrcleAccountAddress(
    poolStateAddress,
    programId,
  );

  return {
    programId: programId,
    creator,
    configId: configAddress,
    authority: authority,
    poolId: poolStateAddress,
    poolStateAddress: poolStateAddress,
    mintA: token0,
    mintB: token1,
    lpMint: lpMintAddress,
    userVaultA: creatorToken0,
    userVaultB: creatorToken1,
    userLpAccount: creatorLpTokenAddress,
    vaultA: vault0,
    vaultB: vault1,
    createPoolFeeAccount: createPoolFee,
    mintProgramA: TOKEN_PROGRAM_ID,
    mintProgramB: TOKEN_PROGRAM_ID,
    observationId: observationAddress,
    amountA: amount0,
    amountB: amount1,
  };
}

function makeCreateCpmmPoolInInstruction(
  programId: PublicKey,
  creator: PublicKey,
  configId: PublicKey,
  authority: PublicKey,
  poolId: PublicKey,
  mintA: PublicKey,
  mintB: PublicKey,
  lpMint: PublicKey,
  userVaultA: PublicKey,
  userVaultB: PublicKey,
  userLpAccount: PublicKey,
  vaultA: PublicKey,
  vaultB: PublicKey,
  createPoolFeeAccount: PublicKey,
  mintProgramA: PublicKey,
  mintProgramB: PublicKey,
  observationId: PublicKey,

  amountMaxA: BN,
  amountMaxB: BN,
  openTime: BN,
): TransactionInstruction {
  const dataLayout = struct([
    u64("amountMaxA"),
    u64("amountMaxB"),
    u64("openTime"),
  ]);

  const keys: Array<AccountMeta> = [
    { pubkey: creator, isSigner: true, isWritable: false },
    { pubkey: configId, isSigner: false, isWritable: false },
    { pubkey: authority, isSigner: false, isWritable: false },
    { pubkey: poolId, isSigner: false, isWritable: true },
    { pubkey: mintA, isSigner: false, isWritable: false },
    { pubkey: mintB, isSigner: false, isWritable: false },
    { pubkey: lpMint, isSigner: false, isWritable: true },
    { pubkey: userVaultA, isSigner: false, isWritable: true },
    { pubkey: userVaultB, isSigner: false, isWritable: true },
    { pubkey: userLpAccount, isSigner: false, isWritable: true },
    { pubkey: vaultA, isSigner: false, isWritable: true },
    { pubkey: vaultB, isSigner: false, isWritable: true },
    { pubkey: createPoolFeeAccount, isSigner: false, isWritable: true },
    { pubkey: observationId, isSigner: false, isWritable: true },

    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    { pubkey: mintProgramA, isSigner: false, isWritable: false },
    { pubkey: mintProgramB, isSigner: false, isWritable: false },
    { pubkey: ASSOCIATED_TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    { pubkey: SYSTEM_PROGRAM_ID, isSigner: false, isWritable: false },
    { pubkey: RENT_PROGRAM_ID, isSigner: false, isWritable: false },
  ];

  const data = Buffer.alloc(dataLayout.span);
  dataLayout.encode(
    {
      amountMaxA,
      amountMaxB,
      openTime,
    },
    data,
  );

  return new TransactionInstruction({
    keys,
    programId,
    // data: Buffer.from([...anchorDataBuf.initialize, ...data]),
    data: Buffer.concat([
      new Uint8Array(anchorDataBuf.initialize),
      new Uint8Array(data),
    ]),
  });
}

export default function useSwapRaydum() {
  const { connection } = useConnection();
  const {
    publicKey,

    signTransaction,
    wallet,
  } = useWallet();
  // const wallet = useAnchorWallet();
  const [isswapSolLoading, setIsswapSolLoading] = useState(false);
  // const token = new PublicKey("Gjo2Xum1dkxrjXSDhBBw4nRPUMiCWiwvT2VWL2tf2ofe");
  // const token = new PublicKey("GGqhU2e92rdGxyD4MCxQq8sK5Xr1P78cVn9vmpHH39nS");
  // const token = new PublicKey("FaHQDuueLx16i3pdWkJxJU6QQdWFSoGCyuZeKPzc9vDR");

  async function wrapSol(
    signTransaction: any,
    connection: Connection,
    walletPublicKey: any,
    amount: number,
  ): Promise<PublicKey> {
    const associatedTokenAccount = await getAssociatedTokenAddress(
      NATIVE_MINT,
      walletPublicKey,
    );

    async function checkIfAccountExists(
      connection: Connection,
      address: PublicKey,
    ): Promise<boolean> {
      const account = await connection.getAccountInfo(address);
      return account !== null;
    }

    const accountExists = await checkIfAccountExists(
      connection,
      associatedTokenAccount,
    );

    let wrapTransaction = new Transaction();

    if (!accountExists) {
      wrapTransaction = new Transaction().add(
        createAssociatedTokenAccountInstruction(
          walletPublicKey,
          associatedTokenAccount,
          walletPublicKey,
          NATIVE_MINT,
        ),
      );
    }

    wrapTransaction.add(
      SystemProgram.transfer({
        fromPubkey: walletPublicKey,
        toPubkey: associatedTokenAccount,
        lamports: amount * LAMPORTS_PER_SOL,
      }),
      createSyncNativeInstruction(associatedTokenAccount),
    );

    wrapTransaction.add(
      ComputeBudgetProgram.setComputeUnitLimit({ units: 1000000 }),
    );
    wrapTransaction.add(
      ComputeBudgetProgram.setComputeUnitPrice({ microLamports: 50000 }),
    );

    const latestBlockHash = await connection.getLatestBlockhash("confirmed");
    wrapTransaction.recentBlockhash = latestBlockHash.blockhash;
    wrapTransaction.feePayer = walletPublicKey;

    const signedTransaction = await signTransaction(wrapTransaction);
    const signature = await connection.sendRawTransaction(
      signedTransaction.serialize(),
    );
    await connection.confirmTransaction({
      blockhash: latestBlockHash.blockhash,
      lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
      signature: signature,
    });

    console.log("✅ - Step 2: SOL wrapped");
    return associatedTokenAccount;
  }

  const swap_buy_raydium = async (
    token: PublicKey,
    amountInStr: String,
    amounOutMin: BN = new BN(10 ** 4),
  ) => {
    if (!publicKey || !wallet || !signTransaction) {
      return;
    }

    const amountIn = new BN(Number(amountInStr) * LAMPORTS_PER_SOL);

    const userInputAccount = await createOrGetTokenAccount(
      signTransaction,
      connection,
      publicKey,
      wSOL,
    );

    console.log("userInputAccount:", userInputAccount.toBase58());

    let adminTokenBalance =
      await connection.getTokenAccountBalance(userInputAccount);

    console.log(`wSOL Balance ${adminTokenBalance.value.uiAmount}`);

    console.log("userOutputAccount");

    const userOutputAccount = await createOrGetTokenAccount(
      signTransaction,
      connection,
      publicKey,
      token,
    );

    console.log("userOutputAccount:", userOutputAccount.toBase58());

    setIsswapSolLoading(true);
    try {
      const amount = amountIn.toNumber() / LAMPORTS_PER_SOL; // Amount of SOL to wrap
      const associatedTokenAccount = await wrapSol(
        signTransaction,
        connection,
        publicKey,
        Number(amount),
      );
      console.log(
        "Associated Token Account:",
        associatedTokenAccount.toBase58(),
      );
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsswapSolLoading(false);
    }

    let accounts = await getCpSwapAccounts(admin, token, amountIn, amounOutMin);

    const dataLayout = struct([u64("amountIn"), u64("amounOutMin")]);

    const keys: Array<AccountMeta> = [
      { pubkey: publicKey, isSigner: true, isWritable: false },
      { pubkey: accounts.authority, isSigner: false, isWritable: false },
      { pubkey: accounts.configId, isSigner: false, isWritable: false },
      { pubkey: accounts.poolId, isSigner: false, isWritable: true },
      { pubkey: userInputAccount, isSigner: false, isWritable: true },
      { pubkey: userOutputAccount, isSigner: false, isWritable: true },
      {
        pubkey: accounts.mintA.equals(wSOL) ? accounts.vaultA : accounts.vaultB,
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: accounts.mintA.equals(wSOL) ? accounts.vaultB : accounts.vaultA,
        isSigner: false,
        isWritable: true,
      },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      { pubkey: wSOL, isSigner: false, isWritable: false },
      { pubkey: token, isSigner: false, isWritable: false },
      { pubkey: accounts.observationId, isSigner: false, isWritable: true },
    ];

    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        amountIn,
        amounOutMin,
      },
      data,
    );

    let instruction = new TransactionInstruction({
      keys,
      programId: accounts.programId,
      data: Buffer.concat([Buffer.from(anchorDataBuf.swapBaseInput), data]),
    });

    const transaction = new Transaction();
    transaction.add(instruction);

    const latestBlockHash = await connection.getLatestBlockhash("confirmed");
    transaction.recentBlockhash = latestBlockHash.blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);
    const signature = await connection.sendRawTransaction(
      signedTransaction.serialize(),
    );

    await connection.confirmTransaction({
      blockhash: latestBlockHash.blockhash,
      lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
      signature: signature,
    });

    console.log("tx", signature);
    return signature;
  };

  const swap_sell_raydium = async (
    token: PublicKey,
    amountInMaxStr: string,
    amountOut: BN = new BN(10 ** 4),
  ) => {
    if (!publicKey || !wallet || !signTransaction) {
      return;
    }

    const amountInMax = convertToLamports(amountInMaxStr);

    let userInputAccount = await createOrGetTokenAccount(
      signTransaction,
      connection,
      publicKey,
      token,
    );
    let userOutputAccount = await createOrGetTokenAccount(
      signTransaction,
      connection,
      publicKey,
      wSOL,
    );

    let accounts = await getCpSwapAccounts(
      admin,
      token,
      amountOut,
      amountInMax,
    );

    const dataLayout = struct([u64("amountInMax"), u64("amountOut")]);

    const keys: Array<AccountMeta> = [
      { pubkey: publicKey, isSigner: true, isWritable: false },
      { pubkey: accounts.authority, isSigner: false, isWritable: false },
      { pubkey: accounts.configId, isSigner: false, isWritable: false },
      { pubkey: accounts.poolId, isSigner: false, isWritable: true },
      { pubkey: userInputAccount, isSigner: false, isWritable: true },
      { pubkey: userOutputAccount, isSigner: false, isWritable: true },
      {
        pubkey: accounts.mintA.equals(token)
          ? accounts.vaultA
          : accounts.vaultB,
        isSigner: false,
        isWritable: true,
      },
      {
        pubkey: accounts.mintA.equals(token)
          ? accounts.vaultB
          : accounts.vaultA,
        isSigner: false,
        isWritable: true,
      },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
      { pubkey: token, isSigner: false, isWritable: false },
      { pubkey: wSOL, isSigner: false, isWritable: false },
      { pubkey: accounts.observationId, isSigner: false, isWritable: true },
    ];

    const data = Buffer.alloc(dataLayout.span);
    dataLayout.encode(
      {
        amountInMax,
        amountOut,
      },
      data,
    );

    let instruction = new TransactionInstruction({
      keys,
      programId: accounts.programId,
      data: Buffer.concat([Buffer.from(anchorDataBuf.swapBaseInput), data]),
    });
    const transaction = new Transaction();
    transaction.feePayer = publicKey;
    transaction.add(instruction);

    const latestBlockHash = await connection.getLatestBlockhash("confirmed");
    transaction.recentBlockhash = latestBlockHash.blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);
    const signature = await connection.sendRawTransaction(
      signedTransaction.serialize(),
    );

    await connection.confirmTransaction({
      blockhash: latestBlockHash.blockhash,
      lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
      signature: signature,
    });

    console.log("tx", signature);
    return signature;
  };

  const [wsol, setWsol] = useState<number | null>(0);
  const searchWsol = async () => {
    if (!publicKey) {
      return;
    }
    const userInputAccount = await createOrGetTokenAccount(
      signTransaction,
      connection,
      publicKey,
      wSOL,
    );

    let adminTokenBalance =
      await connection.getTokenAccountBalance(userInputAccount);
    console.log(`wSOL Balance ${adminTokenBalance.value.uiAmount}`);
    setWsol(adminTokenBalance.value.uiAmount);
  };

  const [raydiumtoken, setraydiumtoken] = useState<number | null>(0);
  const searchtoken = async (token: PublicKey) => {
    if (!publicKey) {
      return;
    }

    const userInputAccount = await createOrGetTokenAccount(
      signTransaction,
      connection,
      publicKey,
      token,
    );

    let adminTokenBalance =
      await connection.getTokenAccountBalance(userInputAccount);
    console.log(`wSOL Balance ${adminTokenBalance.value.uiAmount}`);
    setraydiumtoken(adminTokenBalance.value.uiAmount);
  };

  const unwrapWSOL = async () => {
    if (!publicKey || !signTransaction) {
      throw new Error("Wallet not connected");
    }

    const associatedTokenAccount = await getAssociatedTokenAddress(
      NATIVE_MINT,
      publicKey,
    );

    console.log(
      "Associated Token Account for wSOL:",
      associatedTokenAccount.toString(),
    );

    const transaction = new Transaction().add(
      createCloseAccountInstruction(
        associatedTokenAccount,
        publicKey,
        publicKey,
        [],
      ),
    );

    const latestBlockHash = await connection.getLatestBlockhash("confirmed");
    transaction.recentBlockhash = latestBlockHash.blockhash;
    transaction.feePayer = publicKey;

    const signedTransaction = await signTransaction(transaction);

    const signature = await connection.sendRawTransaction(
      signedTransaction.serialize(),
    );
    let tt = await connection.confirmTransaction({
      blockhash: latestBlockHash.blockhash,
      lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
      signature: signature,
    });
    console.log("Transaction confirmed with signature:", signature);
    return tt;
  };
  return {
    buyRaydium: swap_buy_raydium,
    sellRaydium: swap_sell_raydium,
    searchWsol,
    searchtoken,
    isswapSolLoading,
    wsol,
    raydiumtoken,
  };
}
