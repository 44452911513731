import "./default.sass";
import { useCallback, useRef, useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import { useSolPrice } from "../context/solPriceContext";
import {
  useWalletModal,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { clearUserInfo, getUserInfo, setUserInfo } from "../stores/userStore";
import API from "../API";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import { num2str } from "../lib/utils";

export default function DefaultLayout() {
  const { publicKey, connected, wallet, signMessage } = useWallet();
  const solPrice = useSolPrice();
  let userInfo = getUserInfo();
  let { setVisible } = useWalletModal();
  let isLoging = useRef(false);
  let timerId = useRef<any>(null);

  const { connection } = useConnection();
  const [solBalance, setSolBalance] = useState(0);
  const getSolBalance = async () => {
    if (!publicKey) {
      return;
    }
    try {
      const balance = await connection.getBalance(publicKey);
      setSolBalance(balance / LAMPORTS_PER_SOL);
      console.log("getSolBalance", balance);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(
      "Wallet connection state or public key changed",
      connected,
      publicKey?.toBase58(),
    );

    if (connected && publicKey) {
      clearTimeout(timerId.current);

      if (isLoging.current) {
        console.log("isLoging.current", isLoging.current);
        return;
      }

      if (
        (userInfo.userToken && userInfo.expiringAt < Date.now()) ||
        !userInfo.userToken ||
        (userInfo.address &&
          userInfo?.address?.toLocaleLowerCase() !==
            publicKey?.toBase58().toLocaleLowerCase())
      ) {
        // debugger;
        console.log("login...");
        isLoging.current = true;
        (async () => {
          let nonceRes = await API.userLogin.requestNonce({
            address: publicKey.toBase58(),
            // address: "0x3BB27fC83e530cfC18769167EC1fC2238eC15b71",
          });

          API.r(nonceRes, async () => {
            let nonce = nonceRes.data.value;

            const message = new TextEncoder().encode(nonce);

            if (!signMessage)
              throw new Error("Wallet does not support message signing!");

            const signature = await signMessage(message);
            console.log(bs58.encode(signature));

            const urlParams = new URLSearchParams(window.location.search);
            const referrer = urlParams.get("referrer");
            console.log("referrer: ", referrer);

            let loginRes = await API.userLogin.customerLogin({
              walletAddress: publicKey.toBase58(),
              signature: bs58.encode(signature),
              referrer: referrer ? referrer : "",
            });

            API.r(loginRes, () => {
              setVisible(false);
              setUserInfo({
                ...loginRes.data,
                address: publicKey.toBase58(),
              });

              setTimeout(() => {
                // window.location.reload();
              }, 300);
            });
          });
          isLoging.current = false;
        })();
      }
    } else {
      console.log(connected, publicKey);

      /*


        delay 1 second

      */
      timerId.current = setTimeout(() => {
        clearUserInfo();
      }, 3000);
    }

    return () => {
      clearTimeout(timerId.current);
    };
  }, [connected, publicKey]);

  useEffect(() => {
    setTimeout(() => {
      if (!getUserInfo().userToken) {
        setVisible(true);
      }
    }, 3000);
  }, []);

  const location = useLocation();
  const navHighLight = (path: string) => {
    return location.pathname.startsWith(path) ? "ct-navlink-active" : "";
  };

  const modal = useRef<any>();

  return (
    <main className="ct-font-roboto relative min-h-screen pb-36">
      {/* Header -Section */}
      <div className="mb-12 grid grid-cols-[auto_1fr_auto] items-center px-10 py-5">
        <div className="grid grid-flow-col items-center gap-6">
          <Link to="/" className="grid grid-flow-col items-center gap-6">
            <img
              src="./apple-touch-icon.png"
              alt=""
              className="w-10 rounded-lg"
            />
            <span className="ct-font-silk ct-text-gt1 text-2xl">
              Call Of Meme
            </span>
          </Link>
        </div>
        <div className="ct-navlink grid grid-flow-col justify-center gap-4 text-center text-lg text-white">
          {/* <Link className={isActive} to="/">
            Home
          </Link> */}
          <Link className={navHighLight("/cto")} to="/cto">
            CTO Fund
          </Link>
          <Link className={navHighLight("/tokens")} to="/tokens">
            Memes
          </Link>
          {/* <Link className={navHighLight("/h48r")} to="/h48r">
            48hours rush
          </Link> */}
          <Link className={navHighLight("/nft")} to="/nft">
            NFT
          </Link>
          <Link className={navHighLight("/my")} to="/my">
            Airdrop&Assets
          </Link>
          <span
            className="cursor-pointer"
            onClick={() => modal.current.showModal()}
          >
            How it works
          </span>
        </div>
        <div className="ct-override-wallet-btn">
          <WalletMultiButton />
          <button className="btn h-10 min-h-10 w-[9.25em] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black">
            {publicKey ? publicKey.toBase58().slice(0, 6) : "Connect Wallet"}
          </button>
        </div>
      </div>

      {/* body -Section */}
      <div className="">
        <Outlet />
      </div>

      {/* fotter -Section */}
      <footer className="absolute bottom-0 flex w-full justify-between bg-zinc-800 px-10 py-5 text-white">
        <Link to="/" className="flex gap-2">
          <span className="text-zinc-500">&copy;</span>2024 CALL of MEME
        </Link>
        <div className="flex gap-8">
          <a
            href="https://x.com/calldotmeme"
            target="_blank"
            rel="noopener noreferrer"
          >
            X
          </a>
          <a
            href="https://t.me/calldotmeme"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>
          <Link to="/nft">NFT</Link>
          <p className="ml-10">SOL: ${solPrice}</p>
        </div>
      </footer>

      {/* How it works -Modal */}
      <dialog className="modal" ref={modal}>
        <div className="modal-box max-w-full">
          <h3 className="text-lg font-bold">Mechanism</h3>
          <p>
            Select Tokens → Initiate Proposals → Vote and Bribe → Succeed to
            Obtain CTO Funds
          </p>
          <p className="mt-2 font-bold">1. Process</p>
          <p>
            Users can use $VOTE tokens to create new proposals or cast votes on
            existing ones. Only the winning proposal will have its tokens
            repurchased, with 80% of that season’s CTO fund allocated for this
            purpose.
          </p>
          <p className="mt-2 font-bold">2. Rewards</p>
          <p>
            &nbsp;&nbsp;- The proposal creator receives a 10% reward from the
            fund.
          </p>
          <p>
            &nbsp;&nbsp;- Voters will share 10% of the fund, distributed in
            proportion to the number of votes they cast.
          </p>
          <p className="mt-2">-----</p>
          <p>Q&A</p>
          <p className="mb-2">-----</p>
          <p className="font-bold">Who can initiate a proposal?</p>
          <p>Any user with more than 100 $VOTE can initiate a proposal.</p>
          <p className="mt-2 font-bold">Where does CTO FUND come from?</p>
          <p>
            The initial funding was provided by call.meme, totaling $
            {num2str(100000)}. Each subsequent round of funding will be derived
            from 40% of the total trading volume from the platform's previous
            round.
          </p>
          <p className="mt-2 font-bold">What is the mechanism of CTO FUND?</p>
          <p>
            If the total votes in the current round are fewer than{" "}
            {num2str(150000)} $VOTE, 50% of the CTO FUND will be utilized. If
            the total exceeds this threshold, 100% of the fund will be
            allocated.
          </p>
          <p className="mt-2 font-bold">How to get $VOTE?</p>
          <p>
            $VOTE can be obtained by exchanging $CALL (1 $VOTE = 200 $CALL). As
            the platform evolves, you may require additional $CALL for future
            exchanges.
          </p>
          <p className="mt-2 font-bold">
            How will the $VOTE consumed by voting be handled?
          </p>
          <p>
            20% of the $VOTE used in voting will be burned, while 80% will be
            allocated to the treasury. call.meme will not utilize these $VOTE
            for voting; they will only be available for user redemption.
          </p>
          <p className="mt-2 font-bold">How to get $CALL?</p>
          <p>
            You can earn $CALL rewards by trading memes on the platform.
            Additionally, holding NFTs allows you to gain extra $CALL and
            amplify the rewards from your tradings.
          </p>
          <p className="mt-2 font-bold">
            How long does each round of CTO FUND take?
          </p>
          <p>
            Each round of the CTO Fund spans 30 days, with 25 days allocated for
            voting and the remaining 5 days dedicated to trading and platform
            investment.
          </p>
          <div className="modal-action">
            <form method="dialog">
              <button className="btn">I'm ready to call</button>
            </form>
          </div>
        </div>
      </dialog>
    </main>
  );
}
