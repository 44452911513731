import { useEffect, useRef, useState } from "react";
import {
  CandlestickData,
  ChartOptions,
  ColorType,
  createChart,
  CrosshairMode,
  DeepPartial,
  UTCTimestamp,
} from "lightweight-charts";
import { formatDate } from "../lib/utils";
import API from "../API";
type Props = {
  address: string;
  interval: string;
};

async function fetchBinanceData(
  address: string,
  interval: string,
  from: number,
  to: number,
): Promise<CandlestickData[]> {
  let res = await API.meme.getCandlestick({
    address,
    intervalMinutes: { "1m": 1, "5m": 5, "1h": 60 }[interval],
    startTime: from * 1000,
    endTime: to * 1000,
  });

  let data = res.data.value;
  // debugger;
  return data.map((item: any) => ({
    time: (item.timestamp / 1000) as UTCTimestamp,
    // open: parseFloat(item.open),
    // high: parseFloat(item.high),
    // low: parseFloat(item.low),
    // close: parseFloat(item.close),
    open: item.open,
    high: item.high,
    low: item.low,
    close: item.close,
  }));

  // const url = `https://api.binance.com/api/v3/klines?symbol=${symbol}&interval=${interval}&startTime=${from * 1000}&endTime=${to * 1000}&limit=200`;

  // const response = await fetch(url);
  // if (!response.ok) {
  //   throw new Error(`HTTP error! status: ${response.status}`);
  // }

  // const data = await response.json();

  // return data.map((item: any[]) => ({
  //   time: (item[0] / 1000) as UTCTimestamp,
  //   open: parseFloat(item[1]),
  //   high: parseFloat(item[2]),
  //   low: parseFloat(item[3]),
  //   close: parseFloat(item[4]),
  // }));
}

function getIntervalInSeconds(interval: string): number {
  const units: { [key: string]: number } = {
    m: 60,
    h: 3600,
    d: 86400,
    w: 604800,
    M: 2592000, // 30 days
  };
  const [, num, unit] = interval.match(/(\d+)(\w)/) || [];
  return parseInt(num) * (units[unit] || 0);
}

function mergeData(
  existingData: CandlestickData[],
  newData: CandlestickData[],
  direction: "left" | "right",
): CandlestickData[] {
  const mergedData = [...existingData];

  for (const item of newData) {
    const index = mergedData.findIndex((d) => d.time === item.time);
    if (index === -1) {
      if (direction === "left") {
        mergedData.unshift(item);
      } else {
        mergedData.push(item);
      }
    } else {
      mergedData[index] = item;
    }
  }

  return mergedData.sort((a, b) => (a.time as number) - (b.time as number));
}

export const MEMEChart = ({ address, interval }: Props) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstanse = useRef<any>(null);
  const candlestickSeriesRef = useRef<any>(null); // k line object
  const [data, setData] = useState<CandlestickData[]>([]);

  const isLoadingRef = useRef(false);
  const loadMoreData = async (direction: "left" | "right", time: number) => {
    if (isLoadingRef.current) {
      console.log("is loadMoreData");
      return;
    }
    isLoadingRef.current = true;
    // return;
    const batchSize = 1000; // Binance API limit 1000
    let from: number, to: number;

    if (direction === "left") {
      to = time;
      from = to - batchSize * getIntervalInSeconds(interval);
    } else {
      from = time;
      to = from + batchSize * getIntervalInSeconds(interval);
    }

    try {
      const newData = await fetchBinanceData(address, interval, from, to);
      setData((prevData) => {
        const mergedData = mergeData(prevData, newData, direction);

        if (chartRef.current) {
          candlestickSeriesRef.current.setData(mergedData);
        }
        return mergedData;
      });
    } catch (error) {
      console.error("Failed to fetch more data:", error);
    } finally {
      isLoadingRef.current = false;
    }
  };

  let debounceTimeout: NodeJS.Timeout | null = null;
  const onVisibleLogicalRangeChanged = (logicalRange: any) => {
    if (debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      if (logicalRange) {
        const barsInfo =
          candlestickSeriesRef.current.barsInLogicalRange(logicalRange);
          
        if (barsInfo !== null && barsInfo.barsBefore < 50) {
          loadMoreData("left", barsInfo.from);
        }
        if (barsInfo !== null && barsInfo.barsAfter < 50) {
          loadMoreData("right", barsInfo.to);
        }
      }
    }, 60000)
  };

  useEffect(() => {
    console.log("mounted");

    if (chartRef.current) {
      const chartOptions: DeepPartial<ChartOptions> = {
        layout: {
          background: { color: "#222" },
          textColor: "#DDD",
        },
        grid: {
          vertLines: { color: "#444" },
          horzLines: { color: "#444" },
        },
        timeScale: {
          tickMarkFormatter: (time: UTCTimestamp) => {
            // debugger;
            return formatDate(time * 1000, "MM-dd hh:mm");
          },
        },
        localization: {
          timeFormatter: function (time: UTCTimestamp) {
            return formatDate(time * 1000, "MM-dd hh:mm");
          },
          // dateFormat:'yyyy-MM-dd'
        },
      };
      chartInstanse.current = createChart(chartRef.current, chartOptions);
      const candlestickSeries = chartInstanse.current.addCandlestickSeries({
        upColor: "#26a69a",
        downColor: "#ef5350",
        borderVisible: false,
        wickUpColor: "#26a69a",
        wickDownColor: "#ef5350",
        priceFormat: {
          type: "custom",
          formatter: (price: any) => {
            return `$${price.toFixed(10)}`;
          },
        },
      });
      candlestickSeriesRef.current = candlestickSeries;
      //   candlestickSeries.setData(data);

      const loadInitialData = async function loadInitialData() {
        const endTime = Math.floor(Date.now() / 1000);
        const intervalInSeconds = getIntervalInSeconds(interval)
        const startTime = endTime - 1000 * getIntervalInSeconds(interval);
        try {
          const initialData: any = await fetchBinanceData(
            address,
            interval,
            startTime,
            endTime,
          );
          console.log(initialData);
          let fullData
          if (initialData.length < 100) {
            const candleCnt = 100 - initialData.length
            const defaultData: any[] = new Array(candleCnt).fill(null).map((_, index) => ({
              time: initialData[0].time - (index + 1) * intervalInSeconds,  // 根据时间间隔生成每个空蜡烛的时间戳
              open: initialData[0].open,
              high: initialData[0].open,
              low: initialData[0].open,
              close: initialData[0].open,
            }));
            defaultData.reverse()
            fullData = [...defaultData, ...initialData]
          } else {
            fullData = initialData
          }

          setData(fullData);
          candlestickSeriesRef.current.setData(fullData);
          chartInstanse.current.timeScale().fitContent();
          chartInstanse.current
            .timeScale()
            .subscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChanged);
        } catch (error) {
          console.error("Failed to load initial data:", error);
        }
      };

      loadInitialData();
    }

    // let res = await API.kline.getKlinesInTimeRange({
    //   symbol: symbolType || "ETHUSDT",
    //   interval: changeInterval,
    //   beginTime: rangePickerValues[0] * 1,
    //   endTime: rangePickerValues[1] * 1,
    // });

    return () => {
      setData([]);
      chartInstanse.current
        .timeScale()
        .unsubscribeVisibleLogicalRangeChange(onVisibleLogicalRangeChanged);
      chartInstanse.current.remove();
      console.log("unmounted");
    };
  }, [address, interval]);

  return (
    <div>
      <div className="h-[300px] w-full" ref={chartRef}></div>
    </div>
  );
};
