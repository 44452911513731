import { useParams, useNavigate, Link } from "react-router-dom";
import API from "../API";
import { useEffect, useRef, useState } from "react";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { formatUnit, num2str } from "../lib/utils";
import { PublicKey } from "@solana/web3.js";
import { getAssociatedTokenAddressSync } from "@solana/spl-token";
import { voteTokenAddress } from "../anchor/setup";
import { useVote } from "../hooks/useVote";

export default function Ctofundvote() {
  const { address } = useParams();
  const modal1Ref = useRef(null);
  const [voteNum, setVoteNum] = useState("10");
  const { isLoading, doVote } = useVote();
  const [tx, setTx] = useState("");
  const [tokenInfo, setTokenInfo] = useState({
    tokenInfoResp: {},
    tokenHolderInfoRespList: [],
  });
  console.log(address);

  const getTokenDetail = async function () {
    if (!address) {
      return;
    }
    let res = await API.meme.getTokenDetail({
      address,
    });

    API.r(res, () => {
      setTokenInfo(res.data.tokenInfoResp);
    });
  };

  const [ProposalUserAddress, setProposalUserAddress] = useState("");
  const getProposalUserAddress = async function () {
    let CTOFundingRes = await API.meme.pageCTOFundingList({
      page: {
        limit: 1,
        offset: 0,
      },
    });

    let fundingId = CTOFundingRes.data.fundingId;
    let ProposalUserAddressRes = await API.meme.getProposalUserAddress({
      fundingId,
      tokenAddress: address,
    });

    setProposalUserAddress(ProposalUserAddressRes.data.value);
  };

  useEffect(() => {
    getTokenDetail();
    getProposalUserAddress();
  }, []);

  const voteInputHandler = (value) => {
    if (isNaN(Number(value))) {
      return;
    }
    console.log(value, tokenBalance);
    setVoteNum(value);
    if (value > tokenBalance) {
      setVoteNum(tokenBalance);
    }
  };

  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const [tokenBalance, setTokenBalance] = useState(0);

  const getTokenBalance = async function () {
    if (!publicKey) {
      return;
    }

    let splToken = new PublicKey(voteTokenAddress);
    const traderTokenAccount = getAssociatedTokenAddressSync(
      splToken,
      publicKey,
      true,
    );
    try {
      let t = await connection.getTokenAccountBalance(traderTokenAccount);
      console.log("getTokenBalance", t.value.uiAmount);
      setTokenBalance(t.value.uiAmount);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTokenBalance();
  }, [publicKey]);

  const submitVote = async function () {
    if (isLoading) {
      return;
    }
    setTx("");
    // let t = await doVote(address, ProposalUserAddress, voteNum);
    let t = await doVote(address, publicKey, voteNum);
    setTx(t);
    getTokenBalance();
  };

  const copyToClipboard = (str) => {
    const textarea = document.createElement("textarea");
    textarea.value = str;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  return (
    <>
      <div className="mx-auto flex max-w-[1150px] flex-col items-center">
        {/* <p className="text-[28px] text-[#FF8700]">CTO Fund 16.454 $CALL</p> */}
        <h1 className="ct-font-silk ct-text-gt4 mb-6 text-[56px] text-[#FF8700]">
          Vote for
        </h1>

        <div className="mb-10 rounded-lg border-2 border-[rgb(255_255_255)] border-opacity-5 bg-[#191919] p-5">
          <div className="grid grid-cols-[auto_1fr] gap-6">
            <div className="w-[100px]">
              <Link to="" className="relative">
                <img
                  src={tokenInfo.image}
                  alt=""
                  className="h-[100px] w-full object-cover"
                />
                <img
                  src="./avatar_mask.png"
                  alt=""
                  className="absolute top-0 w-full"
                />
              </Link>
            </div>
            <div className="pt-4">
              <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                Created by
                <img
                  src={tokenInfo.avatar || "/logo.png"}
                  alt=""
                  className="h-5 w-5 object-cover"
                />
                <Link to="" className="text-[#BFDBFE]">
                  {tokenInfo.address ? tokenInfo.address.slice(0, 6) : ""}
                </Link>
              </div>
              <p className="mb-5 text-xs text-[#28E1A6]">
                market cap: {formatUnit(Number(tokenInfo.currentMarketCap))}
              </p>
              <p className="text-sm text-white">
                {tokenInfo.name} [ticker: {tokenInfo.ticker}]
              </p>
            </div>
          </div>
        </div>

        <div className="mb-10 flex text-2xl">
          <p className="mr-4 pt-[8px]">CA:</p>
          <p className="rounded-[4px_0_0_4px] border-2 border-[#374151] px-[10px] py-[6px]">
            {address}
          </p>
          <p className="rounded-[0_4px_4px_0] border-2 border-l-0 border-[#374151]">
            <button
              className="btn h-11 min-h-11 rounded-none border-none bg-transparent text-2xl font-normal"
              onClick={() => copyToClipboard(address)}
            >
              copy
            </button>
          </p>
        </div>

        <p className="mb-10 text-center text-2xl leading-[44px]">
          If the proposal you vote wins, the CTO Fund will be allocated,
          potentially increasing the market cap by up to 120x.
        </p>

        {/* <p className="mb-20 text-[28px] font-bold text-[#FF8700]">
          The Token price will pump 120x if it wins
        </p> */}

        <button
          className="btn h-10 min-h-10 w-[120px] bg-[#FF8700] text-base text-black hover:bg-[#FF8700]"
          onClick={() => modal1Ref.current?.showModal()}
        >
          Vote
        </button>
      </div>

      {/* Vote -Modal */}
      <dialog ref={modal1Ref} className="modal">
        <div className="modal-box border border-white">
          <form method="dialog">
            <button className="btn btn-circle btn-ghost btn-sm absolute right-2 top-2">
              ✕
            </button>
          </form>
          <div className="grid gap-6">
            <h3 className="text-center text-base text-[#C1C1C1]">
              You have {num2str(Number(tokenBalance))} $VOTE
            </h3>
            <div className="flex items-center justify-center gap-5 text-2xl font-bold text-[#FF8700]">
              <span>Use</span>
              <span>
                <input
                  type="text"
                  value={voteNum}
                  onChange={(e) => voteInputHandler(e.target.value)}
                  // placeholder="Type here"
                  className="input input-bordered h-14 w-[170px] bg-[#0D0E0F] text-center text-2xl font-bold text-[#FF8700]"
                />
              </span>
              <span>$VOTE</span>
            </div>
            <div className="flex gap-4">
              {tx ? (
                <a
                  target="_blank"
                  href={`https://explorer.solana.com/tx/${tx}`}
                  className="btn h-11 min-h-11 w-[120px] border border-[#FF8700] text-base font-normal text-[#FF8700] hover:border-[#FF8700]"
                >
                  view tx
                </a>
              ) : (
                ""
              )}

              <button
                onClick={submitVote}
                className="btn h-11 min-h-11 flex-grow bg-[#FF8700] text-base font-normal text-black hover:bg-[#FF8700]"
              >
                Vote
                {isLoading ? (
                  <span className="loading loading-infinity loading-lg text-[#eaeaea]"></span>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
      </dialog>
    </>
  );
}
