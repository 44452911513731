import { create } from "zustand";
import { persist } from "zustand/middleware";

const userInfoTpl = {
  userToken: "",
  expiringAt: 0,
  nickname: "",
  userId: 0,
  address: "",
};

interface UserInfo {
  userToken: string;
  expiringAt: number;
  nickname: string;
  userId: number;
  address: string;
}
interface UserStore {
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
  clearUserInfo: () => void;
}

const storage = {
  getItem: (name: string) => {
    const str = localStorage.getItem(name);
    return str ? JSON.parse(str) : null;
  },
  setItem: (name: string, value: any) => {
    localStorage.setItem(name, JSON.stringify(value));
  },
  removeItem: (name: string) => {
    localStorage.removeItem(name);
  },
};

const useUserStore = create(
  persist(
    (set) => ({
      userInfo: userInfoTpl,
      setUserInfo: (userInfo: UserInfo) => set({ userInfo }),
      clearUserInfo: () => set({ userInfo: userInfoTpl }),
    }),
    {
      name: "user-storage", //  localStorage 
      storage: storage, //  localStorage 
    },
  ),
);

// 
export const getUserInfo = () => {
  const state = useUserStore.getState() as UserStore;
  return state.userInfo;
};

export const setUserInfo = (userInfo: Partial<UserInfo>) => {
  const state = useUserStore.getState() as UserStore;
  state.setUserInfo({
    ...state.userInfo,
    ...userInfo,
  });
};

export const clearUserInfo = () => {
  const state = useUserStore.getState() as UserStore;
  state.clearUserInfo();
};

export default useUserStore;
