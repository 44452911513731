import { useState, useEffect } from "react";
import {
  useConnection,
  useWallet,
  useAnchorWallet,
} from "@solana/wallet-adapter-react";
import { BN } from "bn.js";
import API from "../API";
import { merkleDistributorProgramId, callTokenAddress, RPC, voteTokenAddress } from "../anchor/setup";
import {
  PublicKey,
  SystemProgram,
  SYSVAR_RENT_PUBKEY,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import {
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import { AnchorProvider, Wallet, Idl, Program } from "@coral-xyz/anchor";
import IDL from "../anchor/merkle_distributor.json";

import { toast } from "react-toastify";

global.Buffer = require("buffer").Buffer;

export const useCallToVote = () => {
  const { publicKey, sendTransaction } = useWallet();
  const wallet = useAnchorWallet();
  const { connection } = useConnection();
  const [isConverting, setIsConverting] = useState(false);

  const [distributorInfo, setDistributorInfo] = useState<any>({});
  const getDistributorInfo = async function () {
    let res = await API.meme.getDistributorInfo({type: 2}); // 1:Season, 2:Pump 3:NFT
    API.r(res, () => {
      console.log("res.data.value: ", res.data.value);
      if (res.data && res.data.value) {
        setDistributorInfo(res.data.value[0])
      }
    });
  };

  useEffect(() => {
    getDistributorInfo()
  }, []);

  async function getProvider(rpc: string, wallet: any) {
    const provider = new AnchorProvider(connection, wallet, {
      skipPreflight: true,
    });
    return provider;
  }

  const convertCallToVote = async ({
    amount,
  }: {
    amount: number;
  }) => {
    if (!publicKey || !wallet || !distributorInfo) return;
    setIsConverting(true);

    const provider = await getProvider(RPC, wallet);
    const program = new Program(IDL as Idl, merkleDistributorProgramId, provider);
    const traderCallAccount = await getAssociatedTokenAddressSync(callTokenAddress, wallet.publicKey)
    const traderVoteAccount = await getAssociatedTokenAddressSync(voteTokenAddress, wallet.publicKey)
    const mdVoteAccount = await getAssociatedTokenAddressSync(voteTokenAddress, new PublicKey(distributorInfo.pda), true)

    try {
      let account_list = {
        distributor: new PublicKey(distributorInfo.pda),
        trader: wallet.publicKey,
        splTokenCallMint: callTokenAddress,
        splTokenVoteMint: voteTokenAddress,
        traderCallAccount,
        traderVoteAccount,
        mdVoteAccount,
        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId
      };
      console.log("amount: ", amount);
      
      try {
        let transaction = await program.methods
        .takeVotes(new BN(amount))
        .accounts(account_list)
        .transaction();

        let tx = await provider.sendAndConfirm(transaction);
        toast.success(`Convert ${amount * 200} $Call to ${amount} $Vote Successed!`);
        console.log(`https://solscan.io/tx/${tx}?cluster=devnet`);
        return tx;
      } catch (error) {
        setIsConverting(false);
        toast.error("transaction fail!");
        console.log(error);
      }
    
    } catch (error) {
        console.log('Error minting NFTs: ', error);
        throw error;
    } finally {
      setIsConverting(false);
    }
  };

  return [convertCallToVote, isConverting] as const;
};
