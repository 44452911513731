import {
  PublicKey
} from "@solana/web3.js";

import { ChainConfig } from "./setup.common";
import { devChainConfig } from "./setup.dev";
import { prodChainConfig } from "./setup.prod";

const PROD_DOMAIN = "call.meme";
const currentHostname = window.location.hostname;
const isProdEnv = currentHostname === PROD_DOMAIN;
const testEnvUseProdConfig = false

let chainConfig: ChainConfig;
if (isProdEnv) {
  chainConfig = prodChainConfig;
  console.log(`current hostname: ${currentHostname}, use prod config`);
} else if (testEnvUseProdConfig) {
  chainConfig = prodChainConfig;
  console.log(`current hostname: ${currentHostname}, force to use prod config`);
} else {
  chainConfig = devChainConfig;
  console.log(`current hostname: ${currentHostname}, use dev config`);
}

// exchange pump ProgramId
export const programId = new PublicKey(chainConfig.programId);

// vote ProgramId
export const voteProgramId = new PublicKey(chainConfig.voteProgramId);

// admin address
export const tokenIssuer = new PublicKey(chainConfig.tokenIssuer);

// vote admin address
export const voteAdminAddress = new PublicKey(chainConfig.voteAdminAddress);

// $Call
export const callTokenAddress = new PublicKey(chainConfig.callTokenAddress);

// $Vote
export const voteTokenAddress = new PublicKey(chainConfig.voteTokenAddress);

export const merkleDistributorProgramId = new PublicKey(chainConfig.merkleDistributorProgramId);

export const RPC = chainConfig.heliusRPCEndpoint;

export const vault = new PublicKey(
  "E4fYPKqnV3TpgQ1oULkeDKMmj3Ch6ULc1fg99jRkUTNX",
);
export const dev = new PublicKey(
  "45Y3bVwDQy9HN193nJb4xFb2Mv7c8v6HMTjopvfyxY2e",
);

// Raydium
export const cpSwapProgram = new PublicKey(
  "CPMDWBwJDtYax9qW7AyRuVC19Cc4L4Vcy4n2BHAbHkCW",
);

// for calc pool_id, compose chart address
export const launchConfigAddress = new PublicKey(
  "9zSzfkYy6awexsHvmggeH36pfVUdDGyCcwmjT3AQPBj6",
);

export const DEVNET_PROGRAM_ID = {
  SERUM_MARKET: new PublicKey("11111111111111111111111111111111"),
  OPENBOOK_MARKET: new PublicKey(
    "EoTcMgcDRTJVZDMZWBoU6rhYHZfkNTVEAfz3uUJRcYGj",
  ),
  UTIL1216: new PublicKey("11111111111111111111111111111111"),
  FarmV3: new PublicKey("85BFyr98MbCUU9MVTEgzx1nbhWACbJqLzho6zd6DZcWL"),
  FarmV5: new PublicKey("EcLzTrNg9V7qhcdyXDe2qjtPkiGzDM2UbdRaeaadU5r2"),
  FarmV6: new PublicKey("Farm2hJLcqPtPg8M4rR6DMrsRNc5TPm5Cs4bVQrMe2T7"),
  AmmV4: new PublicKey("HWy1jotHpo6UqeQxx49dpYYdQB8wj9Qk9MdxwjLvDHB8"),
  AmmStable: new PublicKey("DDg4VmQaJV9ogWce7LpcjBA9bv22wRp5uaTPa5pGjijF"),
  CLMM: new PublicKey("devi51mZmdwUJGU9hjN27vEz64Gps7uUefqxg27EAtH"),
  Router: new PublicKey("BVChZ3XFEwTMUk1o9i3HAf91H6mFxSwa5X2wFAWhYPhU"),
  CREATE_CPMM_POOL_PROGRAM: new PublicKey(
    "CPMDWBwJDtYax9qW7AyRuVC19Cc4L4Vcy4n2BHAbHkCW",
  ),
  CREATE_CPMM_POOL_AUTH: new PublicKey(
    "7rQ1QFNosMkUCuh7Z7fPbTHvh73b68sQYdirycEzJVuw",
  ),
  CREATE_CPMM_POOL_FEE_ACC: new PublicKey(
    "G11FKBRaAkHAKuLCgLM6K6NUc9rTjPAznRCjZifrTQe2",
  ),
  FEE_DESTINATION_ID: new PublicKey(
    "3XMrhbv989VxAMi3DErLV9eJht1pHppW5LbKxe9fkEFR",
  ),
};

// NFT

export const CONGRESS_CANDY_MACHINE_ID_STR = chainConfig.congressCandyMachineId;

export const CONGRESS_COLLECTION_ADDRESS = chainConfig.congressCollectionAddress;

export const GOLD_CANDY_MACHINE_ID_STR = chainConfig.goldCandyMachineId;

export const GOLD_COLLECTION_ADDRESS = chainConfig.goldCollectionAddress;

export const SILVER_CANDY_MACHINE_ID_STR = chainConfig.silverCandyMachineId;

export const SILVER_COLLECTION_ADDRESS = chainConfig.silverCollectionAddress;

export const treasuryStr = chainConfig.treasuryAddress;
