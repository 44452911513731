import { useEffect, useState } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import API from "../API";
import { useWindowScroll } from "react-use";
import { cn, num2str, useThrottle } from "../lib/utils";
import { getUserInfo } from "../stores/userStore";
export default function Hours48rushAviablea() {
  const [data, setData] = useState([]); // 
  const [limit, setLimit] = useState(20); // 
  const [offset, setOffset] = useState(0); // 
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // 
  let { rushId } = useParams();
  let [rushTokenList, setRushTokenList] = useState([]);
  const [scrolled, setScrolled] = useState(false); // ，
  let userInfo = getUserInfo();
  let isLogin = userInfo.userToken;
  //
  const { y } = useWindowScroll();
  const checkScrollPosition = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const clientHeight = document.documentElement.clientHeight;
    if (y > 100) {
      setScrolled(true); // 
    }
    if (
      scrolled &&
      y + clientHeight >= scrollHeight - 100 &&
      !loading &&
      hasMore
    ) {
      console.log("bottom...");
      get48HoursRushTokenList();
    }
  };

  //  useThrottle ，200 
  const throttledCheckScroll = useThrottle(checkScrollPosition, 200);

  useEffect(() => {
    throttledCheckScroll();
  }, [y, throttledCheckScroll]);

  const get48HoursRushTokenList = async function () {
    let res = await API.meme.get48HoursRushTokenList({
      rushId,
      page: {
        limit,
        offset,
      },
    });
    res.data.value && setRushTokenList(res.data.value);
  };

  let [user48HoursRushInfo, setUser48HoursRushInfo] = useState({
    address: "",
    nickName: "",
    avatar: "",
    score: 0,
    expectAward: "",
    rank: 0,
  });
  //meme.get48HoursRushTraderList  48HoursRush
  const get48HoursRushTraderList = async function () {
    //48HoursRush
    if (isLogin) {
      let res1 = await API.meme.getUser48HoursRushInfo({
        rushId,
      });
      if (res1.data) {
        setUser48HoursRushInfo(res1.data);
      }
    }
  };

  useEffect(() => {
    get48HoursRushTokenList();
    get48HoursRushTraderList();
  }, [rushId]);

  return (
    <>
      {/* 48 Hours rush -Section */}
      <div className="mx-auto mb-10 flex max-w-[1200px] flex-col">
        <Link to="/h48r" className="mb-10 flex items-center gap-4">
          <img src="/go_back.svg" alt="" />
          <h1 className="ct-font-silk ct-text-gt3 text-[2.5rem]">
            48 Hours rush
          </h1>
        </Link>
        <p className="mb-4 text-3xl text-white">
          Aviable tokens for current round
        </p>
        <p className="text-2xl text-[#C1C1C1]">
          Tips: Buy high Rank token to get higher score
        </p>
      </div>

      {/* Score List -Section */}
      <div className="mx-auto max-w-[1200px] border border-white bg-[#111111]">
        <div className="grid h-[75px] grid-cols-[1fr_auto_auto_auto] items-center justify-between bg-[rgb(0_240_255)] bg-opacity-20 px-6 text-center text-[#fff]">
          <div className="flex items-center gap-2">
            <img
              src={user48HoursRushInfo.avatar || "/logo.png"}
              className="h-10 w-10 rounded-full object-cover"
              alt=""
            />
            <Link to={""} className="text-[#BFDBFE]">
              {user48HoursRushInfo.address.slice(0, 6)}
            </Link>
          </div>
          <div>
            <div className="text-xl">
              {" "}
              #
              {user48HoursRushInfo.rank > 999
                ? "999+"
                : user48HoursRushInfo.rank}
            </div>
            <div className="text-sm text-[#CACACA]">Your Rank</div>
          </div>
          <div className="mx-16">
            <div className="text-xl">{user48HoursRushInfo.score || 0}</div>
            <div className="text-sm text-[#CACACA]">Score</div>
          </div>
          <div>
            <div className="text-xl">{user48HoursRushInfo.expectAward}</div>
            <div className="text-sm text-[#CACACA]">exp.Prize</div>
          </div>
        </div>
        <div className="px-10 pb-7">
          <table className="w-full border-separate border-spacing-y-1.5 text-center text-sm">
            <thead>
              <tr className="h-11 text-[#8E8E92]">
                <th className="font-normal">Token</th>
                <th className="font-normal">Max Mkt Cap</th>
                <th className="font-normal">Current Mkt Cap</th>
                <th className="font-normal">of Holders</th>
                <th className="font-normal">Call for it</th>
                <th className="font-normal">+ Score</th>
              </tr>
            </thead>
            <tbody className="text-[#fff]">
              {rushTokenList.map((_: any, item: any) => (
                <tr key={item} className="h-11 bg-[#151717]">
                  <td>
                    <div className="flex items-center justify-center gap-2">
                      <img
                        src={_.image}
                        className="h-5 w-5 rounded-3xl border border-[#303030]"
                        alt=""
                      />
                      <Link to={""} className="text-[#BFDBFE]">
                        {_.address.slice(0, 6)}
                      </Link>
                    </div>
                  </td>
                  <td>{num2str(Number(_.maxMarketCap))}</td>
                  <td>{num2str(Number(_.currentMarketCap))}</td>
                  <td>{num2str(_.holders)}</td>
                  <td>
                    <Link
                      to={`/tokensdetails/${_.address}`}
                      className="flex items-center justify-center gap-2 font-bold text-[#0AD7A3]"
                    >
                      <i className="flex h-[30px] w-[30px] items-center justify-center rounded-full bg-[#0AD7A3]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                            fill="white"
                          />
                        </svg>
                      </i>
                      BUY
                    </Link>
                  </td>
                  <td className="text-[#28E1A6]">+ {num2str(_.score)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
