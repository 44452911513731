import { useEffect, useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../API";
import { getUserInfo, setUserInfo } from "../stores/userStore";
import { useWallet } from "@solana/wallet-adapter-react";
import "swiper/css";

export default function Airdrop() {
  const { publicKey } = useWallet();

  const [userAirdropSignInfo, setUserAirdropSignInfo] = useState({
    isRegisterAirDrop: false,
    airDropSignInfoRespList: [],
  });

  const [txid, setTxid] = useState("");

  const applyAirdrop = useCallback(
    async function () {
      let res = await API.meme.applyAirDrop({ pumpTxId: txid });
      // console.log("res: ", res);
      if (res.data.value) {
        toast.success("Submit Success", {
          autoClose: 3000,
        });
        setTimeout(() => {
          window.location.href = "/my";
        }, 1000);
      } else {
        const errCode = res.state.code;
        switch (errCode) {
          case 10000100:
            toast.error("System is busy", {
              autoClose: 3000,
            });
            break;
          case 10000206:
            toast.error("Your tx id is not valid", {
              autoClose: 3000,
            });
            break;
          case 10000208:
            toast.error("The registration phase has ended", {
              autoClose: 3000,
            });
            break;
          case 10000209:
            toast.error(
              "We're sorry, all eligible airdrops have been claimed. Thank you for your interest and support!",
              {
                autoClose: 5000,
              },
            );
            break;
          default:
            break;
        }
      }
    },
    [txid],
  );

  useEffect(() => {}, []);

  return (
    <>
      {/* -Section */}
      <div className={`mx-auto mb-32 flex max-w-[1600px] gap-5`}>
        <div className="mx-auto mt-20 min-h-[510px] w-full max-w-[1600px] overflow-hidden rounded-xl border border-[#0AD7A3] bg-[#17181B] p-8">
          <div className={`flex flex-col items-center justify-center`}>
            <h3 className="ct-font-silk ct-text-gt1 mb-4 text-[29px]">
              You will get 300,000 $CALL if you are eligible.
            </h3>
            <p>
              All transactions completed before 23:59:59 (UTC) on December 6,
              2024 are eligible.
            </p>
            <p>
              Limited to the first 10,000 qualified submissions, on a FCFS
              basis.
            </p>
          </div>

          <div className="mx-auto mt-12 w-[61%]">
            <h4 className="ct-font-silk ct-text-gt1 mb-4 text-xl">
              Input your TX ID to activate airdrop *
            </h4>
            <input
              id="name"
              value={txid}
              onChange={(e) => setTxid(e.target.value)}
              className="w-full rounded-md border border-[#666] bg-[#17181B] p-2"
            />
            <p className="mt-4">
              Boost Your Rewards with NFTs:
              <br />
              <br />- Rare NFT: +50% Buff —{" "}
              <Link to="/nft" className="ct-font-silk ct-text-gt1">
                [Go to Mint]
              </Link>
              <br />- Epic NFT: +100% Buff —{" "}
              <Link to="/nft" className="ct-font-silk ct-text-gt3">
                [Go to Mint]
              </Link>
              <br />- Congress Member NFT: +900% Buff —{" "}
              <Link to="/rarenft" className="ct-font-silk ct-text-gt5">
                [Go to Mint]
              </Link>
              <br />
              <br />
              Important Note:Buffs will only take effect if you hold the NFT
              before the airdrop is initialized. NFTs acquired after{" "}
              <span className="ct-font-silk ct-text-gt2">December 15</span> will
              not be eligible for the buff. Don’t miss out—secure your advantage
              now!
            </p>
            {/* <p className="mt-4">
              Mint NFT to get buff: <br />
              Rare NFT +50%, Epic NFT +100% -{" "}
              <a href="/nft" className="ct-font-silk ct-text-gt1">
                Go to Mint
              </a>{" "}
              <br />
              Congress Member +900% -{" "}
              <a href="/rarenft" className="ct-font-silk ct-text-gt5">
                Go to Mint
              </a>
              <br />
              Please note that the buff will take effect only when the airdrop
              is initialized. You wont have it if you get NFT after Nov. 30
            </p> */}
            <div className="mt-[64px] flex h-[72px] items-center justify-center pb-4">
              <button
                className="btn h-10 min-h-min w-[120px] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
                onClick={() => {
                  applyAirdrop();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
