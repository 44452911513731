import {
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
} from "@solana/spl-token";
import { Connection, PublicKey, Transaction } from "@solana/web3.js";

export async function createOrGetTokenAccount(
  signTransaction: any,
  connection: Connection,
  walletPublicKey: PublicKey,
  mintPublicKey: PublicKey,
): Promise<PublicKey> {
  // Associated Token Account
  const associatedTokenAccount = await getAssociatedTokenAddress(
    mintPublicKey,
    walletPublicKey,
  );

  const accountInfo = await connection.getAccountInfo(associatedTokenAccount);
  if (accountInfo) {
    console.log(
      `Token account for mint ${mintPublicKey.toBase58()} already exists:`,
      associatedTokenAccount.toBase58(),
    );
    return associatedTokenAccount;
  }

  const createAccountTransaction = new Transaction().add(
    createAssociatedTokenAccountInstruction(
      walletPublicKey,
      associatedTokenAccount,
      walletPublicKey, 
      mintPublicKey,
    ),
  );

  // getLatestBlockhash
  const latestBlockHash = await connection.getLatestBlockhash("confirmed");
  createAccountTransaction.recentBlockhash = latestBlockHash.blockhash;
  createAccountTransaction.feePayer = walletPublicKey;

  const signedTransaction = await signTransaction(createAccountTransaction);

  const signature = await connection.sendRawTransaction(
    signedTransaction.serialize(),
  );
  await connection.confirmTransaction({
    blockhash: latestBlockHash.blockhash,
    lastValidBlockHeight: latestBlockHash.lastValidBlockHeight,
    signature: signature,
  });

  console.log(
    `Token account for mint ${mintPublicKey.toBase58()} created:`,
    associatedTokenAccount.toBase58(),
  );
  return associatedTokenAccount;
}
