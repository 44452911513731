import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

/*
 **change page & go to top**
 */
export default function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
}
