import { BN } from "bn.js";
import { type ClassValue, clsx } from "clsx";
import { useRef } from "react";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function formatDate(
  timeStamp: string | number | Date,
  fmt: string,
): string {
  const date: Date =
    timeStamp instanceof Date ? timeStamp : new Date(timeStamp);

  interface DateObject {
    [key: string]: number;
  }

  const o: DateObject = {
    "M+": date.getUTCMonth() + 1,
    "d+": date.getUTCDate(),
    "h+": date.getUTCHours(),
    "m+": date.getUTCMinutes(),
    "s+": date.getUTCSeconds(),
    "q+": Math.floor((date.getUTCMonth() + 3) / 3),
    S: date.getUTCMilliseconds(),
  };

  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      date
        .getUTCFullYear()
        .toString()
        .substr(4 - RegExp.$1.length),
    );
  }

  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? o[k].toString() : padStart(o[k], 2, "0"),
      );
    }
  }

  return fmt;

  function padStart(
    value: number,
    targetLength: number,
    padString: string,
  ): string {
    return value.toString().padStart(targetLength, padString);
  }
}

// let file = dataURLtoFile(base64Data, imgName);
export function dataURLtoFile(dataurl: string, filename: string) {
  let arr = dataurl.split(","),
    mime = arr[0]?.match(/:(.*?);/)?.[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export function arrayBufferToBase64(buffer: ArrayBuffer): string {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function convertToLamports(amount: string) {
  const [integer, fraction = ""] = amount.toString().split(".");

  // precision to 9（Solana lamports, 1 SOL = 10^9 lamports）
  const paddedFraction = fraction.padEnd(9, "0");

  const lamports = integer + paddedFraction;
  const trimmedLamports = lamports.replace(/^0+/, "");

  return new BN(trimmedLamports);
}

export function uuid(prefix: string) {
  const time = Date.now();
  const random = Math.floor(Math.random() * 1000000000);
  return prefix + "_" + random + String(time);
}

export function formatUnit(num: number) {
  const units = ["", "k", "M"];
  let i = 0;

  while (num >= 1000 && i < units.length - 1) {
    num /= 1024;
    i++;
  }

  return num.toFixed(2) + " " + units[i];
}

export function formatUnitDecimal(num: number) {
  const units = ["", "k", "M"];
  let i = 0;

  while (num >= 1000 && i < units.length - 1) {
    num /= 1000;
    i++;
  }

  return num.toFixed(2) + " " + units[i];
}

export function useThrottle(fn: Function, delay: number) {
  const lastCall = useRef(0);
  return (...args: any[]) => {
    const now = new Date().getTime();
    if (now - lastCall.current > delay) {
      lastCall.current = now;
      return fn(...args);
    }
  };
}

export function num2str(num: number) {
  if (num === undefined) {
    return "";
  }
  return String(num).replace(/\d+/, (match, p1, p2) => {
    return match.replace(/(\d)(?=(\d{3})+$)/g, ($1) => $1 + ",");
  });
}

export function formatFromNowDateTime(
  timeStamp: number,
  fmt: string = "yyyy-MM-dd hh:mm:ss",
) {
  // get current systime
  const diffTime = Math.abs(Date.now() - timeStamp);
  // console.log("diffTime", diffTime);
  // more then 3 days show fmt
  if (diffTime > 3 * 24 * 60 * 60 * 1000) {
    return formatDate(timeStamp, fmt);
  }
  // more then 1 day show day
  if (diffTime > 24 * 60 * 60 * 1000) {
    return `${Math.floor(diffTime / (24 * 60 * 60 * 1000))} days ago`;
  }
  // more then 1 hour show hour
  if (diffTime > 60 * 60 * 1000) {
    return `${Math.floor(diffTime / (60 * 60 * 1000))} hours ago`;
  }
  // others show minute
  return `${Math.floor(diffTime / (60 * 1000)) || 1} mins ago`;
}

export function copyToClipboard(str: string) {
  const textarea = document.createElement("textarea");
  textarea.value = str;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand("copy");
  document.body.removeChild(textarea);
}

// 过几天等老吴说不要Come Soon就设为 true
export const waitOWOrder = false;
