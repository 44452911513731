import { useState } from "react";
import {
  useConnection,
  useWallet,
  useAnchorWallet,
} from "@solana/wallet-adapter-react";
import { BN } from "bn.js";

import { programId, tokenIssuer, RPC, dev, vault } from "../anchor/setup";
import {
  PublicKey,
  SystemProgram,
  Connection,
  SYSVAR_RENT_PUBKEY,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import {
  getAssociatedTokenAddressSync,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import { AnchorProvider, Wallet, Idl, Program } from "@coral-xyz/anchor";
import IDL from "../anchor/pumpfun.json";
import NodeWallet from "@coral-xyz/anchor/dist/cjs/nodewallet";
import { toast } from "react-toastify";
import { convertToLamports } from "../lib/utils";
global.Buffer = require("buffer").Buffer;

// const pool_id = new PublicKey("AbEraNgYy8KB8a1qnjVpJUfrWQxSVKfN7DeEfdoy4Tn5");

const SOL: PublicKey = new PublicKey(
  "So11111111111111111111111111111111111111112",
);

export const useSell = () => {
  const { publicKey, sendTransaction } = useWallet();
  const wallet = useAnchorWallet();
  const { connection } = useConnection();
  const [isLoading, setIsLoading] = useState(false);

  const pool_id = new PublicKey("GDTxA4WpJoeTnMygRbUXs9zwo3AwJeU7PGUkVstWU8Et");
  async function getProvider(rpc: string, wallet: any) {
    const provider = new AnchorProvider(connection, wallet, {
      skipPreflight: true,
    });
    return provider;
  }

  const sell = async ({
    amount,
    splToken,
    poolAdminPubKey,
  }: {
    amount: string;
    splToken: PublicKey;
    poolAdminPubKey: PublicKey;
  }) => {
    if (!publicKey || !wallet) return;
    setIsLoading(true);

    try {
      const splTokenMint = new PublicKey(splToken);

      console.log(`splTokenMint: ${splTokenMint}`);

      const vaultFeeInfo = PublicKey.findProgramAddressSync(
        [Buffer.from("initialize")],
        programId,
      )[0];
      console.log(`vaultFeeInfo: ${vaultFeeInfo}`);
      const pool = PublicKey.findProgramAddressSync(
        [
          poolAdminPubKey.toBuffer(), //tokenIssuer.toBuffer(),
          splTokenMint.toBuffer(),
          Buffer.from("pool"),
        ],
        programId,
      )[0];

      const input = convertToLamports(amount);

      // const input = new BN(Number(amount) * LAMPORTS_PER_SOL);
      const poolNativeAccount = PublicKey.findProgramAddressSync(
        [
          splTokenMint.toBuffer(),
          tokenIssuer.toBuffer(),
          Buffer.from("pool_native_account_seed"),
        ],
        programId,
      )[0];

      const traderTokenAccount = getAssociatedTokenAddressSync(
        splTokenMint,
        publicKey,
        true,
      );
      const poolTokenAccount = getAssociatedTokenAddressSync(
        splTokenMint,
        poolNativeAccount,
        true,
      );

      let solBalance = await connection.getBalance(publicKey);
      let poolSolBalance = await connection.getBalance(poolNativeAccount);
      let tokenBalance =
        await connection.getTokenAccountBalance(traderTokenAccount);
      let poolTokenBalance =
        await connection.getTokenAccountBalance(poolTokenAccount);
      console.log(
        `after swap sol trader sol: ${solBalance / LAMPORTS_PER_SOL} SOL, trader token: ${tokenBalance.value.uiAmount}`,
      );
      console.log(
        `after swap sol pool sol: ${poolSolBalance / LAMPORTS_PER_SOL} SOL, pool token: ${poolTokenBalance.value.uiAmount}`,
      );
      if (wallet) {
        const provider = await getProvider(RPC, wallet as NodeWallet);
        const program = new Program(IDL as Idl, programId, provider);

        let account_list = {
          pool: pool,
          trader: publicKey,
          splTokenMint: splTokenMint,
          vaultFeeInfo: vaultFeeInfo,
          vault: vault,
          dev: dev,
          poolTokenAccount: poolTokenAccount,
          poolNativeAccount: poolNativeAccount,
          traderTokenAccount: traderTokenAccount,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
          rent: SYSVAR_RENT_PUBKEY,
        };

        let transaction = await program.methods
          .swapExactTokensForSols(input, new BN(100))
          .accounts(account_list)
          .transaction();

        try {
          let tx = await provider.sendAndConfirm(transaction);
          console.log(
            `View on explorer: https://solana.fm/tx/${tx}?cluster=devnet`,
          );
          return tx;
        } catch (error) {
          toast.error("transaction fail!");
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return [sell, isLoading] as const;
};
