import { useNavigate } from "react-router-dom";

export default function Comingsoon() {
  const navigate = useNavigate();

  return (
    <div className="pt-20">
      <h1 className="ct-font-silk ct-text-gt1 text-center text-5xl">
        Call.Meme, $vote to pump your token together.
      </h1>
      <h1 className="ct-font-silk ct-text-gt1 mt-10 text-center text-5xl">
        Coming Soon
      </h1>
      <h1 className="ct-font-silk ct-text-gt1 mt-10 text-center text-3xl">
        Register your pump.fun tx to get airdrop
      </h1>
      <button
        className="btn z-20 mx-auto mt-10 block h-10 min-h-min bg-gradient-to-l from-[#ff5f00] to-[#ffaa00] text-base font-normal text-[#1B1D28]"
        onClick={() => navigate("/my")}
      >
        Register
      </button>
    </div>
  );
}
