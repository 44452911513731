import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: React.FC<ButtonProps> = (props) => (
  <button
    {...props}
    className={`btn h-[40px] min-h-10 min-w-[150px] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black ${props.className}`}
  >
    {props.children}
  </button>
);

export default Button;
