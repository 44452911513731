import { set } from "@coral-xyz/anchor/dist/cjs/utils/features";
import { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { isNum } from "react-toastify/dist/utils";
import API from "../API";
import { toast } from "react-toastify";
import { getUserInfo, setUserInfo } from "../stores/userStore";
import { putFile } from "../lib/upload";
import { formatDate, uuid, copyToClipboard, num2str } from "../lib/utils";
import {
  useConnection,
  useWallet,
  useAnchorWallet,
} from "@solana/wallet-adapter-react";
import { BN } from "bn.js";
import { AnchorProvider, Wallet, Idl, Program } from "@coral-xyz/anchor";
import {
  merkleDistributorProgramId,
  tokenIssuer,
  RPC,
  dev,
  vault,
  cpSwapProgram,
  callTokenAddress,
} from "../anchor/setup";
import {
  getAssociatedTokenAddressSync,
  createAssociatedTokenAccount,
  getAssociatedTokenAddress,
  getAccount,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  createAssociatedTokenAccountInstruction,
  // getOrCreateAssociatedTokenAccount,
} from "@solana/spl-token";
import {
  PublicKey,
  SystemProgram,
  Connection,
  Transaction,
  SYSVAR_RENT_PUBKEY,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";
import { getClaimStatus } from "../anchor/pda";
import IDL from "../anchor/merkle_distributor.json";
import merkleProof from "../anchor/merkleProof.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperClass } from "swiper/types";
import "swiper/css";
import { userInfo } from "os";
import NodeWallet from "@coral-xyz/anchor/dist/cjs/nodewallet";
import { bs58 } from "@coral-xyz/anchor/dist/cjs/utils/bytes";
import moment from "moment-timezone";

import {
  CONGRESS_COLLECTION_ADDRESS,
  GOLD_COLLECTION_ADDRESS,
  SILVER_COLLECTION_ADDRESS,
} from "../anchor/setup";
// import { Metaplex } from "@metaplex-foundation/js";
// import { add, div } from "@raydium-io/raydium-sdk-v2";

export default function Myassets() {
  // const { publicKey } = useWallet();
  const solWallet = useWallet();
  const publicKey = solWallet.publicKey;

  const [showAll, setShowAll] = useState(1);

  /*
   **My proposal tabs**
   */
  const [proposalTabStatus, setProposalTabStatus] = useState("In Progress"),
    baseClass = "cursor-pointer px-4 py-[6px]",
    activeClass = "rounded-badge bg-[#17181B] font-bold text-[#28E1A6]";

  const [updateNickname, setUpdateNickname] = useState(""),
    imageFileRef = useRef<HTMLInputElement>(null),
    [nicknameInput, setNicknameInput] = useState(false),
    [nicknameInputWidth, setNicknameInputWidth] = useState(""),
    inputRef = useRef<HTMLLabelElement>(null);

  const [userIntegralInfo, setUserIntegralInfo] = useState({
    address: "",
    avatar: "",
    points: 0,
    // nftIntegral: 0,
    nickName: "",
    currentSeason: "",
    referralCode: "",
  });

  const targetDate = moment.tz("2024-12-16 00:00:00", "Europe/London").unix();
  const isDec = moment().tz("Europe/London").unix() > targetDate;
  const [userAirdropSignInfo, setUserAirdropSignInfo] = useState({
    isRegisterAirDrop: false,
    airDropSignInfoRespList: [],
  });

  interface Token {
    address: string;
    name: string;
    ticker: string;
    description: string;
    image: string;
    tokenStatus: string;
    creatorAddress: string;
    avatar: string;
    currentMarketCap: string;
  }

  interface UserProposals {
    currentJoinTokenList: Token[];
    beforeJoinTokenList: Token[];
  }

  // NFT
  const [userNFTs, setUserNFTs] = useState([]);
  // TOKEN
  const [userTokens, setUserTokens] = useState<Token[]>([]);
  // Proposal
  const [userProposals, setUserProposals] = useState<UserProposals>({
    currentJoinTokenList: [],
    beforeJoinTokenList: [],
  });
  // Last Season
  const [lastSeasonInfo, setLastSeasonInfo] = useState({
    claimNum: "",
    verifyToken: "",
  });

  const calcInitSlideIndex = () => {
    const itemPosition = monthDates.indexOf(getTodayDate()) + 1;
    const baseIndex = Math.floor(itemPosition / 7) - 1;
    return itemPosition % 7 > 0 ? baseIndex + 1 : baseIndex;
  };

  const [monthDates, setMonthDates] = useState(generateCurrentMonthDates());
  const [activeSlide, setActiveSlide] = useState(calcInitSlideIndex());
  const [swiperInstance, setSwiperInstance] = useState<SwiperClass | null>(
    null,
  );

  const wallet = useAnchorWallet();
  // const { connection } = useConnection();
  const connection = new Connection(RPC, "confirmed");
  const [isLoading, setIsLoading] = useState(false);

  async function getProvider(rpc: string, wallet: any) {
    /* create the provider and return it to the caller */
    /* network set to local network for now */
    // const network = rpc;
    // const connection = new Connection(network, "processed");

    // const provider = new AnchorProvider(connection, wallet, {
    //   skipPreflight: true,
    // });
    const provider = new AnchorProvider(connection, wallet, {
      commitment: "confirmed",
    });
    return provider;
  }

  const [userNFTCount, setUserNFTCount] = useState<any>({
    diamond: 0,
    gold: 0,
    silver: 0,
  });

  const searchAssets = async (ownerAddress: any, collectionAddress: any) => {
    const response = await fetch(RPC, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        id: "25e73bc4-736d-422d-aee9-5baca83c16a1",
        method: "searchAssets",
        params: {
          ownerAddress, //wallet address
          grouping: ["collection", collectionAddress], //epic collection
          page: 1, // Starts at 1
          limit: 1000,
        },
      }),
    });
    const { result } = await response.json();
    return result.total;
  };

  // const metaplex = new Metaplex(connection);
  async function getUserNFTCount() {
    if (!publicKey) return;

    const userDiamondNFTs = await searchAssets(
      publicKey,
      CONGRESS_COLLECTION_ADDRESS,
    );
    const userGoldNFTs = await searchAssets(publicKey, GOLD_COLLECTION_ADDRESS);
    const userSilverNFTs = await searchAssets(
      publicKey,
      SILVER_COLLECTION_ADDRESS,
    );
    console.log("userDiamondNFTs: ", userDiamondNFTs);
    console.log("userGoldNFTs: ", userGoldNFTs);
    console.log("userSilverNFTs: ", userSilverNFTs);
    const holdings = {
      diamond: userDiamondNFTs,
      gold: userGoldNFTs,
      silver: userSilverNFTs,
    };

    // const userNFTs = await metaplex.nfts().findAllByOwner({ owner: publicKey });
    // const holdings = {
    //   diamond: 0,
    //   gold: 0,
    //   silver: 0,
    // };
    // const collectionAddresses = {
    //   diamond: CONGRESS_COLLECTION_ADDRESS,
    //   gold: GOLD_COLLECTION_ADDRESS,
    //   silver: SILVER_COLLECTION_ADDRESS,
    // };
    // const diamondNFT = userNFTs.filter((nft) => {
    //   const collectionAddress = nft.collection?.address.toBase58();
    //   if (collectionAddress === collectionAddresses.diamond) {
    //     holdings.diamond++;
    //   } else if (collectionAddress === collectionAddresses.gold) {
    //     holdings.gold++;
    //   } else if (collectionAddress === collectionAddresses.silver) {
    //     holdings.silver++;
    //   }
    // });

    console.log("User NFT count:", holdings);
    setUserNFTCount(holdings);
    return holdings;
  }

  const nickNameClickHandle = (e: any) => {
    setNicknameInput(true);
    setUpdateNickname(userIntegralInfo.nickName);

    // 1
    if (inputRef.current) inputRef.current.focus();
    // 2
    setNicknameInputWidth(e.target.offsetWidth / 2.3 + 80 + "px");
  };

  const saveEditProfile = async function ({
    name,
    avatar,
  }: {
    name?: string;
    avatar?: string;
  }) {
    let res = await API.userLogin.editProfile({
      avatar: avatar || "",
      nickName: name || "",
    });

    API.r(res, () => {
      if (imageFileRef.current) {
        imageFileRef.current.value = "";
      }

      setUserIntegralInfo({
        ...userIntegralInfo,
        avatar: avatar || "",
        nickName: name || "",
      });
      // toast.success("Edit Success", {
      //   autoClose: 500,
      // });
    });
  };

  type fileItem = {
    fileName: string;
    file: File | null;
  };
  interface UploadResponse {
    cdnUrl?: string;
  }

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      // setImageFile(event.target.files[0]);
      let file = event.target.files[0];
      if (file && file.size > 4.5 * 1024 * 1024) {
        return toast.error(
          "Image too large: it must be less than 4.5 megabytes",
        );
      }

      let imgFileName = `${publicKey?.toBase58()}/${formatDate(
        new Date().getTime(),
        "yyyyMMdd",
      )}/avatar/${uuid("image")}.jpg`;

      const loadingId = toast.loading("uploading image...");

      const putFilePromise = (options: {
        fileList: Array<fileItem>;
        onSuccess?: Function;
        purpose: string;
      }): Promise<UploadResponse> => {
        return new Promise((resolve, reject) => {
          options.onSuccess = (res: UploadResponse) => {
            resolve(res);
          };
          try {
            putFile(options);
          } catch (error) {
            reject(error);
          }
        });
      };

      let uploadImage;
      try {
        uploadImage = await putFilePromise({
          fileList: [{ fileName: imgFileName, file: file }],
          purpose: "userLogin.editProfile",
        });
      } catch (error) {
        console.error(error);
      }

      toast.update(loadingId, {
        render: "upload Success",
        type: "success",
        isLoading: false,
        autoClose: 300,
      });

      let coinImageUrl = uploadImage?.cdnUrl || "";
      console.log(coinImageUrl);
      if (imageFileRef.current) {
        imageFileRef.current.value = "";
      }
      let data = {
        name: userIntegralInfo.nickName,
        avatar: coinImageUrl,
      };

      await saveEditProfile(data);
    }
  };

  const cancelEditProfile = () => {
    setNicknameInput(false);
    setUpdateNickname(userIntegralInfo.nickName);
  };

  const getUserIntegralInfo = async function () {
    if (!publicKey) return;
    let res = await API.meme.getUserIntegralInfo();
    API.r(res, () => {
      setUserIntegralInfo(res.data);
    });
  };

  const getUserLastSeasonInfo = async function () {
    // let res = await API.meme.getLastSeasonClaimInfo();
    // API.r(res, () => {
    //   setLastSeasonInfo(res.data);
    // });
  };

  const getUserAirdropSignInfo = async function () {
    if (!publicKey) return;
    const now = new Date();
    const year = now.getFullYear();
    const startDayStr = monthDates[0];
    const endDayStr = monthDates[monthDates.length - 1];
    let res = await API.meme.getAirDropSignInfo({
      startDay: `${year}-${startDayStr.replace(".", "-")}`,
      endDay: `${year}-${endDayStr.replace(".", "-")}`,
    });
    API.r(res, () => {
      setUserAirdropSignInfo(res.data);
    });
  };

  const getUserToken = async function () {
    if (!publicKey) return;
    let res = await API.meme.getUserToken();
    API.r(res, () => {
      setUserTokens(res.data.value);
    });
  };

  const getUserProposals = async function () {
    if (!publicKey) return;
    let res = await API.meme.getUserJoinCtoFundingInfo();
    API.r(res, () => {
      setUserProposals(res.data);
    });
  };

  const claimAirdrop = async function () {
    if (!publicKey || !wallet || !distributorInfo) return;

    const provider = await getProvider(RPC, wallet);
    const program = new Program(
      IDL as Idl,
      merkleDistributorProgramId,
      provider,
    );

    // const merkleDistributor = merkleDistributorAddress
    const merkleDistributor = new PublicKey(distributorInfo.pda);

    console.log("merkleDistributorStr: ", merkleDistributor.toString());
    console.log("merkleDistributorAddress: ", merkleDistributor);

    //     let proof: any[] = [
    //       bs58.decode("AviZTUycmogKZLwrRWLbSW15CqoGF16VsP59skqfUD9r"),
    //       bs58.decode("25cJ41oNcNNv7XJodrZSN9vzutvLdme5daa5FSrKLUny"),
    //       bs58.decode("Ho1JGSDxA3JEEvmYpkQRf1Y1reomTtZSyRSvg443gsgC"),
    //       bs58.decode("8CescYGXiD5Pgrx5zVLaXBFkRLXs6AQxnMgj1MwpEdr9"),
    //       bs58.decode("H1xV1gA4SddpXUorj1GtuW47ene3U7Kuxk2MRaYo8ny3")
    //     ];
    // console.log(proof);
    let proof: any[] = [];
    // merkleProof.tree_nodes.forEach(item => {
    //   if (item.claimant === wallet.publicKey.toString()) {
    //     proof = item.proof.map(p => bs58.decode(p))
    //   }
    // })
    console.log("proofStrArr: ", distributorInfo.proof);
    proof = distributorInfo.proof.map((p: string) => bs58.decode(p));
    console.log("proof: ", proof);

    const [claimStatus] = await getClaimStatus(
      wallet.publicKey,
      merkleDistributor,
      merkleDistributorProgramId,
    );

    console.log("claimStatus: ", claimStatus.toString());
    const claimStatusAccountInfo = await connection.getAccountInfo(claimStatus);
    console.log("claimStatusAccountInfo: ", claimStatusAccountInfo);

    // YIMIN: hard code
    // const from = new PublicKey("2zq2Tv3o9jawv8wDXt9yEnAobpPC5UddfMYNA9diY2sJ");
    // const from = (await PublicKey.findProgramAddress([
    //       merkleDistributorAddress.toBuffer(),
    //       TOKEN_PROGRAM_ID.toBuffer(),
    //       callTokenAddress.toBuffer()
    //     ],
    //     ASSOCIATED_TOKEN_PROGRAM_ID
    // ))[0];
    // console.log('from', from.toString())

    const from = await getAssociatedTokenAddressSync(
      callTokenAddress,
      merkleDistributor,
      true,
    );
    console.log("from", from.toString());
    // const to = await getOrCreateAssociatedTokenAccount(connection, (wallet as NodeWallet).payer, callTokenAddress, wallet.publicKey, true);

    let transaction = new Transaction();
    // const tokenAccount = await getAccount(connection, wallet.publicKey, 'confirmed', callTokenAddress)
    // console.log("tokenAccount: ", tokenAccount);
    let to = await getAssociatedTokenAddress(
      callTokenAddress,
      wallet.publicKey,
    );
    console.log("to: ", to.toString());
    const tokenAccountInfo = await connection.getAccountInfo(to);
    console.log("tokenAccountInfo: ", tokenAccountInfo);

    if (!tokenAccountInfo) {
      to = (
        await PublicKey.findProgramAddress(
          [
            wallet.publicKey.toBuffer(),
            TOKEN_PROGRAM_ID.toBuffer(),
            callTokenAddress.toBuffer(),
          ],
          ASSOCIATED_TOKEN_PROGRAM_ID,
        )
      )[0];
      console.log("to", to.toString());

      transaction = transaction.add(
        createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          to,
          wallet.publicKey,
          callTokenAddress,
        ),
      );
    }

    // const to = await getAssociatedTokenAddressSync(wallet.publicKey, callTokenAddress)
    console.log("connection", connection);
    // console.log('merkleDistributorAddress', merkleDistributorAddress.toString(), 'callTokenAddress', callTokenAddress.toString())
    console.log("from", from.toString(), "to", to.toString());

    if (!claimStatusAccountInfo) {
      const account_list = {
        distributor: merkleDistributor,
        claimStatus,
        from,
        to,
        claimant: wallet.publicKey,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        associatedTokenProgram: ASSOCIATED_TOKEN_PROGRAM_ID,
      };
      console.log("account_list: ", account_list);

      let claimTx = await program.methods
        // .newClaim(new BN(0), new BN(1000000000000), proof)
        .newClaim(new BN(0), new BN(distributorInfo.claimAmount), proof)
        .accounts(account_list)
        .transaction();
      transaction.add(claimTx);
    }

    const claim_locked_account_list = {
      distributor: merkleDistributor,
      claimStatus,
      from,
      to,
      claimant: wallet.publicKey,
      tokenProgram: TOKEN_PROGRAM_ID,
    };

    let claimLockedTx = await program.methods
      .claimLocked()
      .accounts(claim_locked_account_list)
      .transaction();

    transaction.add(claimLockedTx);

    console.log("transaction: ", transaction);

    let tx;
    try {
      tx = await provider.sendAndConfirm(transaction, [], {
        skipPreflight: true, // skipPreflight
      });
      toast.success("Get Airdrop Successed!");
    } catch (error) {
      console.log(error);
    }
    console.log(tx);
    // debugger;
    console.log(`https://solscan.io/tx/${tx}?cluster=devnet`);
  };

  const claimNFTRewards = async function () {
    if (!publicKey) return;
    let res = await API.meme.getNftHolderClaimInfo();
    API.r(res, () => {
      // TODO: show claim msg
    });
  };

  const [distributorInfo, setDistributorInfo] = useState<any>({});
  const getDistributorInfo = async function () {
    if (!publicKey) return;
    let res = await API.meme.getDistributorInfo({ type: 2 }); // 1:Season, 2:Pump 3:NFT
    API.r(res, () => {
      console.log("res.data.value: ", res.data.value);
      if (res.data && res.data.value) {
        setDistributorInfo(res.data.value[0]);
      }
    });
  };

  function generateCurrentMonthDates(): string[] {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth(); // ，0-based
    const firstDayOfMonth = new Date(year, month, 1); //
    const lastDayOfMonth = new Date(year, month + 1, 0); //

    const startDayOfWeek = firstDayOfMonth.getDay(); // 0-6，0
    const endDayOfWeek = lastDayOfMonth.getDay(); // 0-6，0

    const dates: string[] = [];

    const daysToPrepend = startDayOfWeek === 0 ? 6 : startDayOfWeek - 1; // 1，0

    //
    const lastDayOfPreviousMonth = new Date(year, month, 0).getDate();

    for (let i = daysToPrepend; i > 0; i--) {
      dates.push(
        `${month === 0 ? 12 : month}.${lastDayOfPreviousMonth - i + 1}`,
      );
    }

    //
    const daysInMonth = lastDayOfMonth.getDate();
    for (let day = 1; day <= daysInMonth; day++) {
      dates.push(`${month + 1}.${day}`);
    }

    // （）
    const daysToAppend = endDayOfWeek === 0 ? 0 : 7 - endDayOfWeek;

    //
    for (let day = 1; day <= daysToAppend; day++) {
      dates.push(`${month + 2 === 13 ? 1 : month + 2}.${day}`);
    }

    return dates;
  }

  function getTodayDate(): string {
    const today = new Date();
    const month = today.getMonth() + 1;
    const day = today.getDate();

    return `${month}.${day}`;
  }

  const isOutdated = (date: string) => {
    return monthDates.indexOf(getTodayDate()) > monthDates.indexOf(date);
  };

  const generateDateRow = (rowIndex: number) => {
    return (
      <div className="grid grid-cols-7 gap-1 overflow-hidden">
        {/****
         * item === 0
         * item === 1
         * item === 2
         * item === 3
         * item === 6
         */}
        {/* TODO:  */}
        {monthDates.slice(rowIndex * 7, rowIndex * 7 + 7).map((value, item) => (
          // <div
          //   className={`overflow-hidden rounded py-px ${value === getTodayDate() ? "ct-sign-wrap-bg" : "bg-[#101113]"} ${item === 3 ? "ct-sign-wrap-bg cursor-pointer" : "bg-[#101113]"}`}
          //   key={item}
          // >
          <div
            className={`overflow-hidden rounded py-px ${value === getTodayDate() ? "ct-sign-wrap-bg" : "bg-[#101113]"} ${value === getTodayDate() ? "ct-sign-wrap-bg cursor-pointer" : "bg-[#101113]"}`}
            key={item}
          >
            <div className="bg-[#101113] text-center text-[#858585]">
              <div
                className={`py-0.5 text-xs ${value === getTodayDate() && "text-white"}`}
              >
                {value}
              </div>
              <div
                className={`ct-sign-inner-bg relative mx-px rounded rounded-t-none pb-2 pt-1.5 ${value === getTodayDate() ? "ct-sign-inner-curent-bg" : "ct-sign-inner-bg"}`}
              >
                <img
                  // src={`${item === 6 ? "/i_sign_double.png" : "/i_sign.png"}`}
                  src="/i_sign.png"
                  alt=""
                  className={`mx-auto w-7 ${isOutdated(value) && "opacity-30"}`}
                />
                {/* TODO：，， */}
                {/* {isOutdated(value) && (
                <p className="ct-font-silk absolute left-1/2 top-3 -translate-x-1/2 text-sm">
                  MISS
                </p>
              )} */}
                {/* {item === 1 && (
                <img
                  src="./i_signed.svg"
                  alt=""
                  className="absolute left-1/2 top-3 -translate-x-1/2 text-sm"
                />
              )} */}
                <img
                  src="/i_sign_shadow.svg"
                  alt=""
                  className="mx-auto -mt-1"
                />
                <p
                  className={`text-sm font-bold ${isOutdated(value) && "text-[#383838]"} ${value === getTodayDate() && "text-[#0AD7A3]"}`}
                >
                  {/* {item === 6 ? "+10" : "+1"} */}+
                  {num2str(Number((300000 * (1 + extraNFTRate)) / 30))}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const generateIndexes = (dateLength: number) => {
    const maxIndex = Math.floor(dateLength / 7); //
    return Array.from({ length: maxIndex }, (_, index) => index);
  };

  const generateSwiper = () => {
    return (
      <Swiper
        className="w-full"
        onSwiper={setSwiperInstance}
        onSlideChange={() => {
          setActiveSlide(swiperInstance ? swiperInstance.activeIndex : 0);
        }}
        initialSlide={activeSlide}
        // allowTouchMove={false}
      >
        {generateIndexes(monthDates.length).map((value, index) => (
          <SwiperSlide key={index}>{generateDateRow(value)}</SwiperSlide>
        ))}
      </Swiper>
    );
  };

  useEffect(() => {
    getUserIntegralInfo();
    getUserAirdropSignInfo();
    getUserToken(); // TODO: token
    getUserProposals();
    getUserLastSeasonInfo();
    getDistributorInfo();
  }, []);

  useEffect(() => {
    getUserIntegralInfo();
    getUserAirdropSignInfo();
    getUserToken(); // TODO: token
    getUserProposals();
    getUserLastSeasonInfo();
    getDistributorInfo();
    getUserNFTCount();
  }, [publicKey]);

  const [nftRewards, setNftRewards] = useState(0);
  const [extraRate, setExtraRate] = useState(0);
  const [extraNFTRate, setExtraNFTRate] = useState(0);
  useEffect(() => {
    let nftRewards =
      userNFTCount.silver * 2100000 +
      userNFTCount.gold * 4800000 +
      userNFTCount.diamond * 240000000;
    setNftRewards(nftRewards);

    let extraRate =
      userNFTCount.silver * 30 +
      userNFTCount.gold * 60 +
      userNFTCount.diamond * 100;
    if (extraRate > 300) {
      extraRate = 300;
    }
    extraRate /= 100;
    setExtraRate(extraRate);

    let extraNFTRate =
      userNFTCount.silver * 50 +
      userNFTCount.gold * 100 +
      userNFTCount.diamond * 900;
    if (extraNFTRate > 300) {
      extraNFTRate = 300;
    }
    extraNFTRate /= 100;
    setExtraNFTRate(extraNFTRate);
  }, [userNFTCount]);

  useEffect(() => {
    setActiveSlide(calcInitSlideIndex());
  }, [monthDates]);

  // GET Referral Link
  const modal = useRef<any>();

  const [refUser, setRefUser] = useState([
    {
      address: "",
      hasTrade: false,
    },
  ]);

  const getRefUser = async function () {
    if (!publicKey) return;
    let res = await API.meme.getRefUser();
    API.r(res, () => {
      setRefUser(res.data.value);
    });
  };

  useEffect(() => {
    getRefUser();
  }, []);

  return (
    <>
      {/* user info -Section */}
      <div className={`mx-auto mb-20 flex max-w-[1600px] gap-8`}>
        <div className="relative cursor-pointer">
          <img
            src={userIntegralInfo.avatar || "./apple-touch-icon.png"}
            alt=""
            className="h-20 w-20 rounded-full object-cover"
          />
          <span
            onClick={() => imageFileRef.current?.click()}
            className="absolute left-0 top-0 h-full w-full opacity-0 hover:opacity-100"
          >
            <img
              src="/i_edit.svg"
              alt=""
              className="absolute bottom-0 right-0"
            />
          </span>
          <input
            id="image"
            type="file"
            ref={imageFileRef}
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
          />
        </div>
        <div className="grid items-center">
          <div className="relative h-10 min-w-[34rem]">
            <h2
              className={`relative z-20 w-max cursor-pointer pr-8 text-4xl font-bold text-white ${nicknameInput ? "hidden" : "block"}`}
              onClick={nickNameClickHandle}
            >
              {userIntegralInfo.nickName ||
                userIntegralInfo.address.slice(0, 6)}
              <span className="absolute left-0 top-0 h-full w-full opacity-0 hover:opacity-100">
                <img
                  src="/i_edit.svg"
                  alt=""
                  className="absolute bottom-1.5 right-0"
                />
              </span>
            </h2>
            <div
              className={`absolute z-10 flex gap-4 ${nicknameInput ? "opacity-100" : "opacity-0"}`}
            >
              <label
                ref={inputRef}
                className="input input-bordered flex h-10 items-center gap-2 focus-within:outline-[#0AD7A3]"
              >
                <input
                  type="text"
                  style={{ width: nicknameInputWidth }}
                  value={updateNickname}
                  onChange={(e) => setUpdateNickname(e.target.value)}
                  // onBlur={() => setNicknameInput(false)}
                />
              </label>
              <button
                onClick={async () => {
                  await saveEditProfile({
                    name: updateNickname,
                    avatar: userIntegralInfo.avatar,
                  });

                  setNicknameInput(false);
                }}
                className="btn h-10 min-h-min rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
              >
                Save
              </button>

              <button
                onClick={cancelEditProfile}
                className="btn h-10 min-h-min rounded-lg bg-[#333] text-base text-[#a3a3a3] hover:bg-[#333]"
              >
                Cancel
              </button>
            </div>
            <button
              className="btn absolute bottom-0 right-0 z-20 h-10 min-h-min bg-gradient-to-l from-[#ff5f00] to-[#ffaa00] text-base font-normal text-[#1B1D28]"
              onClick={() => {
                copyToClipboard(
                  `https://call.meme/?referrer=${userIntegralInfo.referralCode}`,
                );
                getRefUser();
                modal.current.showModal();
              }}
            >
              invite friends
            </button>
          </div>

          <p className="relative z-30 text-lg text-[#999]">
            wallet address {userIntegralInfo.address}
          </p>
        </div>
      </div>

      {/* -Section */}
      <div className={`mx-auto mb-32 flex max-w-[1600px] gap-5`}>
        <div className="h-[510px] w-[600px] overflow-hidden rounded-xl border border-[#0AD7A3] bg-[#17181B] p-8">
          {/* <div className={`flex justify-center border-white border-opacity-20`}> */}
          <div
            className={`flex justify-center ${userAirdropSignInfo.isRegisterAirDrop ? "border-b" : ""} border-white border-opacity-20`}
          >
            <h3 className="ct-font-silk ct-text-gt1 text-[40px]">Airdrop</h3>
          </div>
          {/* Pump.fun Reward -Block */}
          <div className="ct-reward-wrap-bg relative">
            {!userAirdropSignInfo.isRegisterAirDrop && (
              <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-90">
                <div>
                  <p className="ct-font-silk ct-text-gt1 mb-4 text-xl">
                    You are not eligible for pump.fun airdrop
                  </p>
                  <button
                    className="btn mx-auto block h-10 min-h-min w-[120px] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
                    onClick={() => {
                      window.location.href = "/airdrop";
                    }}
                  >
                    Apply Now
                  </button>
                </div>
              </div>
            )}
            {userAirdropSignInfo.isRegisterAirDrop && !isDec && (
              <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-90">
                <div>
                  <p className="ct-font-silk ct-text-change mb-4 text-lg">
                    You are aligible for Pump.fun airdrop
                  </p>
                  <p className="ct-font-silk ct-text-gt1 mb-1 text-lg">
                    Total:{" "}
                    {num2str(
                      Number(
                        300000 +
                          150000 * userNFTCount.silver +
                          300000 * userNFTCount.gold +
                          2700000 * userNFTCount.diamond,
                      ),
                    )}{" "}
                    $Call
                  </p>
                  <p className="ct-font-silk ct-text-gt1 mb-1 text-sm">
                    300,000 $Call - Basic Airdrop
                  </p>
                  <p className="ct-font-silk ct-text-gt1 mb-1 text-sm">
                    150,000 x {userNFTCount.silver} $Call - Rare NFT Buff
                  </p>
                  <p className="ct-font-silk ct-text-gt1 mb-1 text-sm">
                    300,000 x {userNFTCount.gold} $Call - Epic NFT Buff
                  </p>
                  <p className="ct-font-silk ct-text-gt1 mb-4 text-sm">
                    2,700,000 x {userNFTCount.diamond} $Call - Congress Member
                    NFT Buff
                  </p>
                  <p className="ct-font-silk ct-text-gt1 mb-4 text-lg">
                    starting from Dec 16th.
                  </p>
                </div>
              </div>
            )}
            <div className="ct-reward-inner-bg">
              <div className="flex items-center justify-between px-6 pb-4 pt-4">
                <div className="flex gap-2">
                  <img src="/i_gift.svg" alt="" />
                  <h4 className="ct-font-silk ct-text-gt1 text-xl">
                    Pump.fun Reward
                  </h4>
                </div>
                {showAll % 2 === 0 && ( // claim
                  <p>
                    to claim all <span className="text-[#28E1A6]">$80</span>{" "}
                    Rewards <span className="text-[#28E1A6]">&gt;</span>
                  </p>
                )}
              </div>
              <div className="mb-7 grid grid-cols-[auto_1fr_auto] items-center gap-1.5">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    swiperInstance?.slideTo(
                      activeSlide === 0 ? 0 : activeSlide - 1,
                    );
                  }}
                >
                  <img src="/i_arrow_left.svg" alt="" />
                </div>
                {generateSwiper()}
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    swiperInstance?.slideTo(
                      activeSlide >= monthDates.length / 7
                        ? activeSlide
                        : activeSlide + 1,
                    );
                  }}
                >
                  <img src="/i_arrow_right.svg" alt="" />
                </div>
              </div>
              {/* {showAll % 2 === 1 && ( // claim
                <div className="flex items-center justify-between px-6 pb-4">
                  <div className="grid gap-1">
                    <p className="ct-font-silk text-sm text-[#858585]">
                      all month reward
                    </p>
                    <p className="flex items-center gap-2 text-[#F5F5F5]">
                      <span className="text-[32px] leading-none">80</span>
                      <span className="text-xl">$call</span>
                    </p>
                  </div>
                  <div>
                    <button className="btn h-10 min-h-min w-[120px] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black">
                      Claim All
                    </button>
                    <button className="btn h-10 min-h-min w-[120px] cursor-not-allowed rounded-lg bg-[#111111] text-base text-[#666666] hover:bg-[#111111]">
                      Claimed
                    </button>
                  </div>
                </div>
              )} */}
              {/* {showAll % 2 === 0 && ( // claim */}
              <div className="flex h-[72px] items-center justify-center pb-4">
                <button
                  className="btn h-10 min-h-min w-[120px] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
                  onClick={() => {
                    claimAirdrop();
                  }}
                >
                  Claim
                </button>
                {/* <button className="btn h-10 min-h-min w-[120px] cursor-not-allowed rounded-lg bg-[#111111] text-base text-[#666666] hover:bg-[#111111]">
                    Claimed
                  </button> */}
              </div>
              {/* )} */}
            </div>
          </div>

          {/* NFT REWARD -Block */}
          <div className="ct-ntf-bg mt-5 h-[120px] rounded-lg">
            <div className="flex items-center gap-2 pb-4 pl-6 pt-4">
              <img src="/i_gift.svg" alt="" />
              <h4 className="ct-font-silk ct-text-gt1 text-xl">NFT REWARD</h4>
            </div>
            <div className="flex items-center justify-between px-6">
              <p className="flex items-center gap-2 text-[#F5F5F5]">
                <span className="text-[40px] leading-none">
                  {showAll % 2 === 0 ? "0" : num2str(Number(nftRewards))}
                </span>
                <span className="text-[28px]">$call</span>
              </p>
              <div>
                {showAll % 2 === 1 && ( // NFT,Claim  Claimed
                  <>
                    {/* <button
                      className="btn h-10 min-h-min w-[120px] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black"
                      onClick={() => {
                        // claimNFTRewards()
                        // claimAirdrop()
                      }}
                    >
                      Claim
                    </button> */}
                    <span
                      className="tooltip tooltip-bottom cursor-pointer"
                      data-tip="Available on Dec 16th."
                    >
                      <button className="btn h-10 min-h-min w-[120px] cursor-not-allowed rounded-lg bg-[#111111] text-base text-[#666666] hover:bg-[#111111]">
                        Claim
                      </button>
                    </span>
                  </>
                )}
                {showAll % 2 === 0 && ( // NFT
                  <div className="text-[#C3C3C7]">
                    5,000 rewards if you have a{" "}
                    <a
                      href="http://g.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#28E1A6]"
                    >
                      Platform NFT &gt;
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Season -Block */}
        <div className="ct-season-wrap-bg relative flex-grow">
          <div className="ct-season-inner-bg h-full text-center text-[#0AD7A3]">
            <div className="mb-6">
              <h3 className="mb-5 mt-11 flex items-center justify-center gap-4">
                <img src="/ss_l.svg" alt="" />
                <span className="ct-font-silk ct-text-gt1 text-[40px]">
                  Season {userIntegralInfo.currentSeason}
                </span>
                <img src="/ss_r.svg" alt="" />
              </h3>
              <p className="flex items-center justify-center gap-2 text-2xl">
                Score
                <span
                  className="tooltip tooltip-bottom cursor-pointer"
                  data-tip="Points will be earned through trading, with additional bonuses for holding NFTs(No more than +300%). These points can redeem $CALL at a predetermined ratio during specified periods."
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    className="opacity-50"
                  >
                    <path
                      d="M8.75 0.25C3.92188 0.25 0 4.17188 0 9C0 13.8281 3.92188 17.75 8.75 17.75C13.5781 17.75 17.5 13.8281 17.5 9C17.5 4.17188 13.5781 0.25 8.75 0.25ZM9.375 14H8.125V12.75H9.375V14ZM9.59375 10.0312L9.48438 10.125C9.42187 10.1719 9.375 10.2812 9.375 10.375V11.5156H8.125V10.375C8.125 9.89063 8.34375 9.4375 8.70312 9.14062L8.8125 9.04688C9.90625 8.17188 10.4375 7.71875 10.4375 6.9375C10.4375 6 9.6875 5.25 8.75 5.25C7.78125 5.25 7.0625 5.96875 7.0625 6.9375H5.8125C5.8125 5.29688 7.10938 4 8.75 4C10.375 4 11.6875 5.3125 11.6875 6.9375C11.6875 8.35938 10.7188 9.14062 9.59375 10.0312Z"
                      fill="#B8B8B8"
                    />
                  </svg>
                </span>
              </p>
              <p className="text-[45px] font-bold">
                {(userIntegralInfo.points * (1 + extraRate)).toFixed(2)}
              </p>
            </div>
            <div className="mx-16 mb-2 flex h-[76px] items-center justify-between rounded-lg bg-[#132324] px-6 text-2xl">
              <span>Trading Rewards:</span>
              <span>{userIntegralInfo.points}</span>
            </div>
            {(showAll % 4 === 1 || showAll % 4 === 3) && ( // season1 nft
              <div className="mx-16 flex h-[76px] items-center justify-between rounded-lg bg-[#132324] px-6 text-2xl">
                <span>NFT Extra Rewards:</span>
                <span>+{(userIntegralInfo.points * extraRate).toFixed(2)}</span>
              </div>
            )}
            {(showAll % 4 === 2 || showAll % 4 === 0) && ( // season1 nft
              <div className="mx-16 flex h-[76px] items-center justify-center rounded bg-white bg-opacity-5 px-6">
                <span className="text-[#C3C3C7]">
                  81,123 More Transaction Points if you have A&nbsp;
                </span>
                <a
                  href="http://g.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Platform NFT &gt;
                </a>
              </div>
            )}
            {(showAll % 4 === 1 || showAll % 4 === 2) && ( // season1
              <p className="mt-12 text-sm">
                {/* - when season {userIntegralInfo.currentSeason} finished we will
                count the final Transaction Points and transfer to $call - */}
                After the 1st season ends, we will count the trading points and
                convert them into $call
              </p>
            )}
            {(showAll % 4 === 3 || showAll % 4 === 0) && ( // season2
              <div className="absolute bottom-0 flex h-20 w-full items-center rounded-xl bg-[#0A4145] px-6 text-[#FFD42F]">
                <div className="flex items-center gap-2">
                  <img src="/ss_s_l.svg" alt="" />
                  <span className="ct-font-silk ct-text-gt5 min-w-max text-xl">
                    Season {parseInt(userIntegralInfo.currentSeason) - 1}
                  </span>
                  <img src="/ss_s_r.svg" alt="" />
                </div>
                <p className="grow pl-6 text-left text-lg">
                  Congratulations! You can get{" "}
                  <strong className="text-[#0AD7A3]">
                    {lastSeasonInfo.claimNum} $call
                  </strong>{" "}
                  from season {parseInt(userIntegralInfo.currentSeason) - 1}
                </p>
                <>
                  {showAll % 4 === 3 && (
                    <button className="btn h-10 min-h-min max-w-[120px] rounded-lg bg-gradient-to-r from-[#00FF80] to-[#00FFE2] text-base text-black">
                      Claim
                    </button>
                  )}
                  {showAll % 4 === 0 && (
                    <button className="btn h-10 min-h-min max-w-[120px] cursor-not-allowed rounded-lg border-none bg-[#111111] bg-opacity-40 text-base text-[#1C575B] hover:bg-[#111111] hover:bg-opacity-40">
                      Claimed
                    </button>
                  )}
                </>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* nft and buff -Section */}
      {/* 202492316:31:20 -------  */}
      {/* <div className={`mx-auto mb-24 max-w-[1600px]`}>
        <div className="mb-6 flex items-center justify-between">
          <h3 className="ct-font-silk ct-text-gt1 text-[2.5rem]">
            nft and buff
          </h3>
          <Link to="" className="flex gap-2 text-xl text-white">
            View all<span>&gt;</span>
          </Link>
        </div>
        <ul className="grid grid-cols-4 gap-4 text-white">
          {new Array(7).fill(0).map((_, item, index) => (
            <li className="bg-black bg-opacity-50" key={item}>
              <img src="/nft_sample.jpg" alt="" className="w-full" />
              <p className="ct-font-silk flex items-center justify-between p-4 text-xl">
                <span className={`${item === 0 && "ct-text-gt5"}`}>
                  Kanpai Pandas
                </span>
                <span className="h-16 w-16 overflow-hidden">
                  <img
                    src={`./i_${(item === 0 && "gold") || (item === 1 && "silver") || (item > 1 && "bronze")}.svg`}
                    alt=""
                    className="-ml-[10px] -mt-[2px] w-[84px] max-w-none"
                  />
                </span>
              </p>
            </li>
          ))}
        </ul>
      </div> */}

      {/* My Token -Section */}
      <div className={`mx-auto mb-24 max-w-[1600px]`}>
        <h3 className="ct-font-silk ct-text-gt1 mb-6 inline-block text-[2.5rem]">
          My Token
        </h3>
        {userTokens.length === 0 && (
          <p className="ct-font-silk ct-text-gt1 mb-4 text-xl">
            You don’t have any token yet.
          </p>
        )}
        {userTokens.length > 0 && (
          <div className="grid grid-cols-3 gap-6">
            {userTokens.map((item, index) => (
              <div
                className="rounded-lg border-2 border-[rgb(255_255_255)] border-opacity-5 bg-[#191919] p-5"
                key={index}
              >
                <div className="grid grid-cols-[auto_1fr] gap-6">
                  <div className="w-[100px]">
                    <Link
                      to={`/tokensdetails/${item.address}`}
                      className="relative"
                    >
                      <img
                        src={item.image ? item.image : "/avatar_sample.jpg"}
                        alt=""
                        className="w-full"
                      />
                      <img
                        src="/avatar_mask.png"
                        alt=""
                        className="absolute top-0 w-full"
                      />
                    </Link>
                  </div>
                  <div className="pt-4">
                    <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                      Created by
                      <img
                        src={item.avatar ? item.avatar : "/avatar_sample_2.png"}
                        alt=""
                        className="max-w-[16px]"
                      />
                      <Link to="/" className="text-[#BFDBFE]">
                        {item.creatorAddress.slice(0, 6)}
                      </Link>
                    </div>
                    <p className="mb-4 text-xs text-[#28E1A6]">
                      market cap: {item.currentMarketCap}
                    </p>
                    <p className="text-sm text-white">
                      {item.name} [ticker: {item.ticker}]
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* My proposal -Section */}
      <div className={`mx-auto max-w-[1600px]`}>
        <h3 className="ct-font-silk ct-text-gt1 mb-6 inline-block text-[2.5rem]">
          My proposal
        </h3>
        <div className="mb-7 flex gap-4 text-sm text-white">
          <span
            className={`${baseClass} ${proposalTabStatus === "In Progress" && activeClass}`}
            onClick={() => setProposalTabStatus("In Progress")}
          >
            In Progress
          </span>
          <span
            className={`${baseClass} ${proposalTabStatus === "Finished" && activeClass}`}
            onClick={() => setProposalTabStatus("Finished")}
          >
            Finished
          </span>
        </div>
        {proposalTabStatus === "In Progress" &&
          userProposals.currentJoinTokenList.length === 0 && (
            <p className="ct-font-silk ct-text-gt1 mb-4 text-xl">
              You don’t have a proposal in progress yet.
            </p>
          )}
        {proposalTabStatus === "In Progress" &&
          userProposals.currentJoinTokenList.length > 0 && (
            <div className="grid grid-cols-3 gap-6">
              {userProposals.currentJoinTokenList.map((item, index) => (
                <div
                  className="rounded-lg border-2 border-[rgb(255_255_255)] border-opacity-5 bg-[#191919] p-5"
                  key={index}
                >
                  <div className="grid grid-cols-[auto_1fr_auto] gap-6">
                    <div className="w-[100px]">
                      <Link
                        to={`/tokensdetails/${item.address}`}
                        className="relative"
                      >
                        <img
                          src={item.image ? item.image : "/avatar_sample.jpg"}
                          alt=""
                          className="w-full"
                        />
                        <img
                          src="/avatar_mask.png"
                          alt=""
                          className="absolute top-0 w-full"
                        />
                      </Link>
                    </div>
                    <div className="pt-4">
                      <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                        Created by
                        <img
                          src={
                            item.avatar ? item.avatar : "/avatar_sample_2.png"
                          }
                          alt=""
                          className="max-w-[16px]"
                        />
                        <Link to="/" className="text-[#BFDBFE]">
                          {item.creatorAddress.slice(0, 6)}
                        </Link>
                      </div>
                      <p className="mb-4 text-xs text-[#28E1A6]">
                        market cap: {item.currentMarketCap}
                      </p>
                      <p className="text-sm text-white">
                        {item.name} [ticker: {item.ticker}]
                      </p>
                    </div>
                    <div className="grid items-center">
                      <Link
                        to={`/ctovote/${item.address}`}
                        className="grid gap-1 text-[#FF8700]"
                      >
                        <i className="mx-auto flex h-10 w-10 items-center justify-center rounded-full bg-[#FF8700]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.2716 12.0186C12.947 11.9315 12.451 12.4812 12.451 12.4812C12.2924 12.6397 12.1485 12.9073 12.0213 13.1914C11.8182 13.6453 11.2487 13.8137 10.8367 13.5352C9.1586 12.4011 7.53646 10.7747 6.41102 9.09579C6.14077 8.69264 6.29476 8.14144 6.72847 7.92358C7.12454 7.72464 7.49061 7.53231 7.49061 7.53231L7.48763 7.52238C7.75847 7.3991 7.91081 7.09437 7.8314 6.79795C7.8314 6.79795 5.56568 1.25043 4.91788 1.25043C2.89185 1.25043 1.24902 2.89325 1.24902 4.91928C1.24902 5.384 1.33868 5.82425 1.49767 6.23191C3.19756 12.1306 7.84071 16.7697 13.7394 18.4737C14.1471 18.6327 14.5914 18.7223 15.0561 18.7223C17.0821 18.7223 18.7556 17.1773 18.7556 15.1513C18.7556 15.1513 15.9642 13.1007 13.2716 12.0186Z"
                              fill="white"
                            />
                          </svg>
                        </i>
                        VOTE
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        {proposalTabStatus === "Finished" &&
          userProposals.beforeJoinTokenList.length === 0 && (
            <p className="ct-font-silk ct-text-gt1 mb-4 text-xl">
              You don’t have any finished proposal yet.
            </p>
          )}
        {proposalTabStatus === "Finished" &&
          userProposals.beforeJoinTokenList.length > 0 && (
            <div className="grid grid-cols-3 gap-6">
              {userProposals.beforeJoinTokenList.map((item, index) => (
                <div
                  className="rounded-lg border-2 border-[rgb(255_255_255)] border-opacity-5 bg-[#191919] p-5"
                  key={index}
                >
                  <div className="grid grid-cols-[auto_1fr_auto] gap-6">
                    <div className="w-[100px]">
                      <Link
                        to={`/tokensdetails/${item.address}`}
                        className="relative"
                      >
                        <img
                          src={item.image ? item.image : "/avatar_sample.jpg"}
                          alt=""
                          className="w-full"
                        />
                        <img
                          src="/avatar_mask.png"
                          alt=""
                          className="absolute top-0 w-full"
                        />
                      </Link>
                    </div>
                    <div className="pt-4">
                      <div className="mb-1 grid grid-flow-col justify-start gap-2 text-xs">
                        Created by
                        <img
                          src={
                            item.avatar ? item.avatar : "/avatar_sample_2.png"
                          }
                          alt=""
                          className="max-w-[16px]"
                        />
                        <Link to="/" className="text-[#BFDBFE]">
                          {item.creatorAddress.slice(0, 6)}
                        </Link>
                      </div>
                      <p className="mb-4 text-xs text-[#28E1A6]">
                        market cap: {item.currentMarketCap}
                      </p>
                      <p className="text-sm text-white">
                        {item.name} [ticker: {item.ticker}]
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
      </div>

      {/* Get Rerrfe -Modal */}
      <dialog className="modal" ref={modal}>
        <div className="modal-box max-w-md">
          <h3 className="text-lg font-bold">Your referral Link</h3>
          <div className="flex items-center justify-between">
            https://call.meme/?referrer={userIntegralInfo.referralCode}
            <span className="w-24">
              <span className="rounded bg-[#B0F9B2] px-[4px] text-black">
                Copied!
              </span>
            </span>
          </div>
          <h3 className="mt-6 text-lg font-bold">Invitation List</h3>
          {refUser.length > 0 ? (
            refUser.map((_, item) => (
              <div className="flex items-center justify-between" key={item}>
                Address: {_.address.slice(0, 7)}....{_.address.slice(-7)}
                <span className="w-24">
                  {_.hasTrade && <img src="./i_signed.svg" alt="" />}
                  {!_.hasTrade && <span>Not trade yet</span>}
                </span>
              </div>
            ))
          ) : (
            <div>Nothing!</div>
          )}
          <div className="modal-action">
            <form method="dialog">
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>
    </>
  );
}
