import { ajaxGateway } from "../http";

/**
 * PUT file
 * @options
 *  fileList [{fileName = '', file = DOM element}, ...]
 *  purpose APIserive use ',' to seperate
 *  onProgress
 *  onSuccess
 *  onError
 */
export async function putFile(options) {
  let { fileList, purpose, onSuccess, onError, onProgress } = options;
  if (!fileList.length || !purpose) {
    throw new Error("params error");
  }

  let filenames = fileList.map((item) => item.fileName);
  // get ticket
  return ajaxGateway({
    mt: "file.requestS3UploadingTicket",
    data: {
      filenames: JSON.stringify(filenames),
      purpose,
    },
  }).then((res) => {
    if (
      res.state.code == 0 &&
      res.data &&
      Array.isArray(res.data.fileUploadingItemList)
    ) {
      res.data.fileUploadingItemList.forEach((item) => {
        console.log(item);
        let {
          urlForUploading = "",
          // path = "", 
          uploadingHeaders = [],
          originFilename = "",
          cdnUrl = "",
        } = item;

        const index = filenames.indexOf(originFilename);
        if (index >= 0) {
          let option = {};
          let headers = {};
          uploadingHeaders.forEach((item) => (headers[item.name] = item.value));

          option.headers = headers;
          option.action = urlForUploading;
          option.method = "put";
          option.file = fileList[index].file;
          option.withCredentials = false;
          option.cdnUrl = cdnUrl;

          onProgress || (onProgress = () => {});
          // onProgress || (onProgress = (progress) => {});

          onError ||
            (onError = (xhr) => {
              console.log("upload err:", xhr.code);
            });

          onSuccess ||
            (onSuccess = () => {
              console.log("upload success:", { originFilename, cdnUrl });
            });

          option.onProgress = onProgress;
          option.onError = onError;
          option.onSuccess = onSuccess;

          upload(option);
        }
      });
    } else {
      throw new Error("upload err");
    }
  });
}

function upload(option) {
  const xhr = new XMLHttpRequest();
  const action = option.action;
  const method = option.method;

  if (xhr.upload) {
    xhr.upload.onprogress = function (e) {
      if (e.total > 0) {
        e.percent = (e.loaded / e.total) * 100;
      }
      option.onProgress(e);
    };
  }

  let requestData = option.file;

  xhr.onerror = function (e) {
    option.onError(e);
  };

  xhr.onload = function onload() {
    if (xhr.status < 200 || xhr.status >= 300) {
      return option.onError(xhr);
    }

    option.onSuccess({ cdnUrl: option.cdnUrl });
  };

  xhr.open(method, action, true);

  if (option.withCredentials && "withCredentials" in xhr) {
    xhr.withCredentials = true;
  }

  const headers = option.headers || {};

  for (let item in headers) {
    if (headers.hasOwnProperty(item) && headers[item] !== null) {
      xhr.setRequestHeader(item, headers[item]);
    }
  }

  xhr.send(requestData);

  return xhr;
}

// let file = dataURLtoFile(base64Data, imgName);
export function dataURLtoFile(dataurl, filename) {
  let arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}
